import React from "react";
import HeaderBack from "../components/HeaderBack";
import {useSelector} from 'react-redux'

function EditProfile() {
  const {primary} = useSelector((state)=>state.theme)
  return (
    <div>
      <HeaderBack title="Edit Profile" />
      <div>
        <form className="mt-2 flex justify-center column align-center ">
          <div>
            <img
              src="../images/userImage.png"
              className="logo  account-image mb-1 "
            />
          </div>
          <input
            type="text"
            placeholder="Full Name "
            className="browser-default input "
          />
          <input
            type="email"
            placeholder="Email Address"
            className="browser-default input "
          />
          <input
            type="text"
            placeholder="Phone Number"
            className="browser-default input "
          />
          <button className="btn full-width " style={{ backgroundColor:primary }}>Save Information</button>
        </form>
      </div>
    </div>
  );
}

export default EditProfile;
