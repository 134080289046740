import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const { primary } = useSelector((state) => state.theme);
  const navigate = useNavigate();

  return (
    <div
      className="flex align-center   column p-2 justify-center "
      style={{ textAlign: "center", height: "80vh" }}
    >
      <img src="../images/404.jpg" />
      <b className="normal-size mv-1 ">We're Sorry</b>
      <b> It looks like you've stumbled upon a page that doesn't exist.</b>
      <button
        className="btn-submit mt-2 "
        style={{ backgroundColor: primary }}
        onClick={() => navigate(-1)}
      >
        Go Back
      </button>
    </div>
  );
}

export default NotFound;
