import React from "react";
import ContentLoader  from "react-content-loader";

function CardLoading(props) {
  return (
    <div>
      <div className="flex gap-2 ">
        {" "}
        <ContentLoader
          speed={2}
          width="100%"
          backgroundColor="#e6e6e6"
          foregroundColor="#ffffff"
          radius={"5px"}
          {...props}
        >
          <rect x="0" y="0" width="100%" height="120" rx="5" />
        </ContentLoader>
        <ContentLoader
          speed={2}
          width="100%"
          backgroundColor="#e6e6e6"
          foregroundColor="#ffffff"
          radius={"5px"}
          {...props}
        >
          <rect x="0" y="0" width="100%" height="120" rx="5" />
        </ContentLoader>
      </div>

      <div className="flex gap-2 " style={{ marginTop: "-15px" }}>
        {" "}
        <ContentLoader
          speed={2}
          width="100%"
          backgroundColor="#e6e6e6"
          foregroundColor="#ffffff"
          radius={"5px"}
          {...props}
        >
          <rect x="0" y="0" width="100%" height="120" rx="5" />
        </ContentLoader>
        <ContentLoader
          speed={2}
          width="100%"
          backgroundColor="#e6e6e6"
          foregroundColor="#ffffff"
          radius={"5px"}
          {...props}
        >
          <rect x="0" y="0" width="100%" height="120"  rx="5"/>
        </ContentLoader>
      </div>
    </div>
  );
}
CardLoading.metadata = {
  name: "Bar Loading",
  github: "sridhareaswaran",
  description: "Meta Bin bar loading",
  filename: "TableLoader",
};
export default CardLoading;
