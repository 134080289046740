import React from "react";
import { useLottie } from "lottie-react";
import LocationLading from "../lotti/locationLoading.json";

function LocationLoading() {
  const options = {
    animationData: LocationLading,
    loop: true,
  };
  const { View } = useLottie(options);
  return (
    <div className="flex column align-center justify-center ">
      <span style={{ width:"250px" }}>{View}</span>
      <p className="cercle-purple-text">searching...</p>
    </div>
  );
}

export default LocationLoading;
