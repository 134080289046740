import { Auth, metaBinManagement } from "../config/configURL.js";
import { toast } from "react-toastify";
import { showLoading, hideLoading } from "./loading";
import { getUserLocation } from "./metaBinAction.js";

export const registerUser = (phoneNumber, setAuthStep) => async (dispatch) => {
  dispatch(showLoading());
  Auth.post(`/account/create/step-1`, {
    phoneNumber: phoneNumber,
  })
    .then((res) => {
      res.data && dispatch(hideLoading());
      dispatch({
        type: "CREATE_ACCOUNT_STEP1",
        payload: res.data?.session,
        phoneNumber: phoneNumber,
      });
      setAuthStep(2);
    })
    .catch((error) => {
      (() => {
        dispatch(hideLoading());
        toast.error(error.response ? error.response?.data : error.message);
      })();
    });
};

export const resendOTP =
  (phoneNumber, startTimer, setResendLoading) => async (dispatch) => {
    setResendLoading(true);
    Auth.post(`/account/create/step-1`, {
      phoneNumber: phoneNumber,
    })
      .then((res) => {
        startTimer();
        setResendLoading(false);
      })
      .catch((error) => {
        setResendLoading(false);
        toast.error(error.response ? error.response?.data : error.message);
      });
  };

export const getLocationAction = () => async (dispatch) => {
  navigator.geolocation.getCurrentPosition((position) => {
    const lat = position.coords.latitude;
    const log = position.coords.longitude;
    dispatch(getUserLocation(lat, log));
  });
};

export const confirmOTP =
  (phoneNumber, OTP, session, setAuthStep, referrer) => async (dispatch) => {
    dispatch(showLoading());

    try {
      Auth.post("/account/create/step-2", {
        phoneNumber: phoneNumber,
        OTP: OTP,
        sessionId: session,
      })
        .then((res) => {
          if (res.data?.session) {
            dispatch(hideLoading());
            setAuthStep(3);
          } else {
            try {
              if (typeof res.data === "string") {
                localStorage.setItem("CCXT", res.data);

                const config = {
                  headers: {
                    "x-authentication-token": res.data,
                  },
                };

                metaBinManagement
                  .post(`/authenticate?referrer=${referrer}`, {}, config)
                  .then((res) => {
                    dispatch(hideLoading());
                    localStorage.setItem("METABIN_CCXT", res.data);
                    window.location.href = "/home";
                  });
              } else if (typeof res.data.token === "string") {
                localStorage.setItem("CCXT", res.data.token);

                const config = {
                  headers: {
                    "x-authentication-token": res.data.token,
                  },
                };

                metaBinManagement
                  .post(`/authenticate?referrer=${referrer}`, {}, config)
                  .then((res) => {
                    dispatch(hideLoading());
                    localStorage.setItem("METABIN_CCXT", res.data);
                    window.location.href = "/home";
                  });
              }
            } catch (error) {
              dispatch(hideLoading());

              if (error.response && error.response.status === 502) {
                return dispatch({
                  type: "CANNOT_CONFIRM_OTP",
                  errorMessage:
                    "We are having trouble connecting to our servers. Please try again later.",
                });
              }

              if (
                (error.response && error.response.status === 404) ||
                (error.response && error.response.status === 400) ||
                (error.response && error.response.status === 401)
              ) {
                return dispatch({
                  type: "CANNOT_CONFIRM_OTP",
                  errorMessage: "Please login again to continue.",
                });
              }

              if (!error.response) {
                return dispatch({
                  type: "CANNOT_CONFIRM_OTP",
                  errorMessage:
                    "Please check your internet connection and try again.",
                });
              }
            }
          }
        })
        .catch((error) => {
          dispatch(hideLoading());

          if (error.response && error.response.status === 502) {
            return dispatch({
              type: "CANNOT_CONFIRM_OTP",
              errorMessage:
                "We are having trouble connecting to our servers. Please try again later.",
            });
          }

          if (
            (error.response && error.response.status === 404) ||
            (error.response && error.response.status === 400) ||
            (error.response && error.response.status === 401)
          ) {
            return dispatch({
              type: "CANNOT_CONFIRM_OTP",
              errorMessage: "Please login again to continue.",
            });
          }

          if (!error.response) {
            return dispatch({
              type: "CANNOT_CONFIRM_OTP",
              errorMessage:
                "Please check your internet connection and try again.",
            });
          }
        });
    } catch (error) {
      dispatch(hideLoading());
      if (error.response && error.response.status === 502) {
        return dispatch({
          type: "CANNOT_CONFIRM_OTP",
          errorMessage:
            "We are having trouble connecting to our servers. Please try again later.",
        });
      }

      if (
        (error.response && error.response.status === 404) ||
        (error.response && error.response.status === 400) ||
        (error.response && error.response.status === 401)
      ) {
        return dispatch({
          type: "CANNOT_GET_BRANDS",
          errorMessage: "Please login again to continue.",
        });
      }

      if (!error.response) {
        return dispatch({
          type: "CANNOT_GET_BRANDS",
          errorMessage: "Please check your internet connection and try again.",
        });
      }
    }
  };

export const accountInformationAction =
  (navigate, name, sessionId, referrer) => async (dispatch) => {
    dispatch(showLoading());
    try {
      const { data } = await Auth.post(`/account/create/step-3`, {
        sessionId: sessionId,
        name: name,
      });

      const config = {
        headers: {
          "x-authentication-token": data.token,
        },
      };

      const { data: mtbToken } = await metaBinManagement.post(
        `/authenticate?referrer=${referrer}`,
        {},
        config
      );
      dispatch(hideLoading());
      localStorage.setItem("METABIN_CCXT", mtbToken);
      window.location.href = "/home";

    } catch (error) {
      dispatch(hideLoading());
      toast.error(error.response ? error.response?.data : error.message);
    }
  };

export const getAccountInfo = () => async (dispatch) => {
  dispatch(showLoading());
  Auth.get("/account/read")
    .then((res) => {
      dispatch(hideLoading());
      dispatch({ type: "GET_USER_INFO_SUCCESS", payload: res.data });
    })
    .catch((error) => {
      dispatch(hideLoading());
      if (error.response.status === 404) return localStorage.removeItem("CCXT");
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const loginUser = (medium, password) => async (dispatch) => {
  dispatch(showLoading());

  try {
    const { data: token } = await Auth.post(
      "/account/login/step-1?method='OTP'",
      {
        medium: "fade@cerclex.com",
        password: "MyPassword@123",
      }
    );
    dispatch(hideLoading());
    localStorage.setItem("CCXT", token);
    try {
      const config = {
        headers: {
          "x-authentication-token": token,
        },
      };
      const { data } = await metaBinManagement.post(
        "/authenticate",
        {},
        config
      );
      localStorage.setItem("METABIN_CCXT", data);
      window.location.href = "/home";
    } catch (error) {
      toast.error(error.response ? error.response?.data : error.message);
    }
  } catch (error) {
    dispatch(hideLoading());
    toast.error(error.response ? error.response?.data : error.message);
  }
};

export const logoutAction = (navigate) => async () => {
  try {
    localStorage.removeItem("CCXT");
    localStorage.removeItem("METABIN_CCXT");
    localStorage.removeItem("primary-color");
    localStorage.removeItem("secondary-color");
    localStorage.setItem("primary-color", "#6f2da8");
    localStorage.setItem("secondary-color", "#f0f0f0");
    navigate("/");
    window.location.reload();
  } catch (error) {
    toast.error(error.response ? error.response?.data : error.message);
  }
};
