export const MOTION_VARIANTS = {
  initial: (direction) => ({
    y: direction === "backward" ? "-100%" : "100%",
    transition: {
      type: "spring",
      duration: 1,
      delay: 0
    }
  }),
  in: {
    y: 0,
    transition: {
      type: "spring",
      duration: 1,
      delay: 0
    }
  },
  out: (    direction) => ({
    y: direction === "backward" ? "100%" : "-100%",
    transition: {
      type: "spring",
      duration: 1,
      delay: 0
    }
  })
};
