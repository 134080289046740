import React from "react";
import ContentLoader from "react-content-loader";
import { useSelector } from "react-redux";

function UserLoading(props) {
  const { primary, secondary } = useSelector((state) => state.theme);

  return (
    <div>
      {" "}
      <ContentLoader
        speed={2}
        width="100%"
        backgroundColor={primary}
        foregroundColor="#ffffff"
        radius={"5px"}
        height={"18px"}
        {...props}
      >
        <rect x="0" y="0" width="37%" height="7.5" rx="2" />
        <rect x="0" y="11" width="57%" height="6.5" rx="2" />
      </ContentLoader>
    </div>
  );
}

UserLoading.metadata = {
  name: "Bar Loading",
  github: "sridhareaswaran",
  description: "Meta Bin bar loading",
  filename: "TableLoader",
};

export default UserLoading;
