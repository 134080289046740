import React, { useState, useEffect } from "react";
import {
  Collapsible,
  CollapsibleItem,
  Icon,
  Modal,
  Button,
  Switch
} from "react-materialize";
import HeaderBack from "../components/HeaderBack";
import NotificationSettings from "../components/NotificationSettings";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { logoutAction } from "../redux/action/auth";

function ManageAccount() {
  const { primary } = useSelector((state) => state.theme);
  const [currentTheme, setCurrentTheme] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChangeThemeColor = () => {
    if (currentTheme !== "#6f2da8") {
      localStorage.setItem("previous-theme", currentTheme);
      localStorage.setItem("primary-color", "#6f2da8");
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    } else {
      const previousTheme = localStorage.getItem("previous-theme");
      if (previousTheme) {
        localStorage.setItem("primary-color", previousTheme);
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      } else {
        localStorage.setItem("primary-color", "#6f2da8");
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
    }
  };

  useEffect(() => {
    setCurrentTheme(localStorage.getItem("primary-color"));
  }, []);

  return (
    <div>
      <HeaderBack title=" Manage Account " />

      <Collapsible accordion={false}>
        <CollapsibleItem
          expanded={false}
          header={
            <div className="flex space-between full-width ">
              <b>Notification Settings</b>{" "}
              <span className="material-symbols-outlined  ">expand_more</span>{" "}
            </div>
          }
          icon={
            <span
              className="material-symbols-outlined  "
              style={{ color: primary }}
            >
              notifications
            </span>
          }
          node="div"
        >
          <NotificationSettings />
        </CollapsibleItem>
        <CollapsibleItem
          expanded={false}
          header={
            <div className="flex space-between full-width ">
              <b>Theme</b>{" "}
              <span className="material-symbols-outlined">expand_more</span>{" "}
            </div>
          }
          icon={
            <span
              className="material-symbols-outlined "
              style={{ color: primary }}
            >
              dark_mode
            </span>
          }
          node="div"
        >
          <div className="flex space-between">
            <b>Primary Theme </b>{" "}
            <Switch
              id="Switch-22"
              onChange={() => onChangeThemeColor()}
              checked={currentTheme == "#6f2da8"}
            />
          </div>
        </CollapsibleItem>
      </Collapsible>

      <Modal
        trigger={
          <div className="flex space-between p-1  white border-bottom  ">
            <div className="flex align-center">
              <span
                className="material-symbols-outlined primary-icon-color "
                style={{ color: primary }}
              >
                logout
              </span>
              &nbsp;
              <b>Logout</b>
            </div>
            <span className="material-symbols-outlined">expand_more</span>
          </div>
        }
        header="LOGOUT"
        id="Modal-12"
        open={false}
        actions={[]}
        bottomSheet={false}
        fixedFooter={false}
      >
        <b className="normal-size">Are you sure want to logout?</b>
        <div className="flex mt-1 justify-end  ">
          <Button
            flat
            modal="close"
            node="button"
            waves="green"
            className="btn-small small  mr-1"
            style={{
              backgroundColor: "#fff",
              color: primary,
              border: `1px solid ${primary}`,
              fontWeight: "bold"
            }}
          >
            No
          </Button>
          <Button
            flat
            modal="close"
            node="button"
            waves="green"
            className="btn-small small  mr-1  white-text "
            style={{
              backgroundColor: primary,
              padding: "0 22px",
              fontWeight: "bold"
            }}
            onClick={() => dispatch(logoutAction(navigate))}
          >
            Yes
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default ManageAccount;
