import React from "react";

function BinProgressLine({ binFull }) {
  return (
    <div style={{ width: "100%", position: "relative" }}>
      <div className="main-line"></div>
      <div className="full-line" style={{ width: `${binFull}%` }}></div>
    </div>
  );
}

export default BinProgressLine;
