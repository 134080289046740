import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getClosestMetaBinsAction } from "../redux/action/metaBinAction";
import SingleBin from "./SingleBin";
import BarLoading from "./BarLoading";

function NearbyBins() {
  const dispatch = useDispatch();
  const { closestBins, loading } = useSelector((state) => state.closestBins);

  function getClosestMetaBins() {
    navigator.geolocation.getCurrentPosition((position) => {
      const lat = position.coords.latitude;
      const log = position.coords.longitude;
      dispatch(getClosestMetaBinsAction(lat, log));
    });
  }

  useEffect(() => {
    getClosestMetaBins();
  }, []);

  return (
    <div className="mt-1 mb-3 ">
      <b className="normal-size p-1 ">Nearby Meta Bins:</b>
      {loading && <BarLoading />}
      {closestBins.length === 0 || !closestBins ? (
        <div className="flex column align-center mt-2 ">
          <img
            src="../images/emptyBin.png"
            style={{ width: "100px", height: "100px" }}
            alt="emptyBin"
            loading="lazy"
          />
          <p className="grey-text">Sorry you have no bins near you.</p>
        </div>
      ) : (
        <div>
          {closestBins?.map((item, index) => {
            if (index < 4)
              return (
                <div key={index}>
                  <Link to={`/single-bin-screen/${item._id}`}>
                    <SingleBin item={item} />
                  </Link>
                </div>
              );
          })}
          <span className="flex column align-center mt-1 ">
            <p className="grey-text small-text">
              These are all the meta bins near your location.
            </p>
          </span>
        </div>
      )}
    </div>
  );
}

const styles = {};

export default NearbyBins;
