import React, { useState, useEffect } from "react";
import HeaderBack from "../components/HeaderBack";
import { useSelector, useDispatch } from "react-redux";
import {
  getSingleSessionAction,
  rateExperience
} from "../redux/action/metaBinAction";
import { Modal, Button } from "react-materialize";
import { useParams } from "react-router-dom";
import UserLoading from "../components/UserLoading";
import BarLoading from "../components/BarLoading";

function SingleSession() {
  const { sessionID } = useParams();
  const dispatch = useDispatch();

  const { loading, sessionData } = useSelector(
    (state) => state.getSingleSession
  );

  useEffect(() => {
    dispatch(getSingleSessionAction(sessionID));
  }, [dispatch]);

  return (
    <div>
      <HeaderBack title={"Single Session Details"} />

      <div className="flex column  mt-2 full-width ">
        <div className="flex align-center justify-center ">
          <img src="../images/bin.png" style={{ width: "150px" }} />
        </div>

        {loading ? (
          <BarLoading />
        ) : (
          <div className="p-1 border-bottom mt-2">
            <div className="flex space-between ">
              <b>Brand Name</b>
              <p>{sessionData.metabin?.owner?.name}</p>
            </div>
            <div className="flex space-between ">
              <b>Number Of Bottles</b>
              <p>{sessionData.metabin?.barcodes?.length}</p>
            </div>

            <div className="flex space-between gap-2">
              <b>Location</b>
              <p className="ml-5" >{sessionData.metabin?.address}</p>
            </div>
            <div className="flex align-end full-width justify-end mt-2 "></div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SingleSession;

//{" "}
<div className="flex space-between ">
  // <b>Rewards Coins </b>
  // <p>12</p>
  //{" "}
</div>;
