import React from "react";
import HeaderBack from "../components/HeaderBack";
import "animate.css";
import { useSelector } from "react-redux";

function LearnAboutMetaBin() {
  const { primary } = useSelector((state) => state.theme);

  return (
    <div className="bg-page">
      <HeaderBack title={" About MetaBin "} />
      <stepLine />
      <div className="flex align-center justify-center column p-1 ">
        <img
          src="../images/bin.png"
          className=" p-1 mb-2 "
          style={{ width: "150px" }}
        />
        <ul className="flex column align-center  ">
          <li className="box flex  gap-1 full-width  p-1 animate__animated animate__fadeInLeft  ">
            {" "}
            <img src="../images/scanIcon.png" style={styles.imageIcon} />
            <b>Scan the QR Code on the Bin to open the Metabin App.</b>
          </li>
          <span
            className="material-symbols-outlined"
            style={{ color: primary }}
          >
            more_vert
          </span>
          <li className=" box flex  gap-1 full-width  p-1 animate__animated animate__fadeInRight">
            {" "}
            <img src="../images/user.png" style={styles.imageIcon} />
            <b>Register an account with your personal information.</b>
          </li>{" "}
          <span
            className="material-symbols-outlined"
            style={{ color: primary }}
          >
            more_vert
          </span>
          <li className=" box flex  gap-1 full-width align-center  p-1 animate__animated animate__fadeInLeft">
            {" "}
            <img src="../images/barCode.png" style={styles.imageIcon} />
            <b>Scan product barcode on the binscorner.</b>
          </li>{" "}
          <span
            className="material-symbols-outlined"
            style={{ color: primary }}
          >
            more_vert
          </span>
          <li className=" box flex  gap-1 full-width  p-1 animate__animated animate__fadeInRight">
            {" "}
            <img src="../images/droping.png" style={styles.imageIcon} />
            <b>Start dropping products into the Metabin's bin to earn coins.</b>
          </li>{" "}
          <span
            className="material-symbols-outlined"
            style={{ color: primary }}
          >
            more_vert
          </span>
          <li className=" box flex align-center gap-1 full-width p-1 animate__animated animate__fadeInLeft">
            {" "}
            <img src="../images/coins.png" style={styles.imageIcon} />
            <b>Check your coin balance in the app.</b>
          </li>{" "}
          <span
            className="material-symbols-outlined"
            style={{ color: primary }}
          >
            more_vert
          </span>
          <li className=" box flex  gap-1 full-width  p-1 animate__animated animate__fadeInRight">
            {" "}
            <img src="../images/search.png" style={styles.imageIcon} />
            <b>
              Browse the reward catalog to see the various sustainable products,
              vouchers, and other items that can be redeemed with your coins.
            </b>
          </li>{" "}
          <span
            className="material-symbols-outlined"
            style={{ color: primary }}
          >
            more_vert
          </span>
          <li className=" box flex align-center gap-1 full-width p-1 animate__animated animate__fadeInLeft">
            {" "}
            <img src="../images/activateRewards.png" style={styles.imageIcon} />
            <b>
              Confirm the redemption by entering the number of coins you want to
              redeem for the selected item.
            </b>
          </li>
        </ul>
      </div>
    </div>
  );
}

const styles = {
  imageIcon: {
    width: "40px",
    height: "40px"
  },
  stepLine: {}
};

export default LearnAboutMetaBin;
