import React from "react";
import HeaderBack from "../components/HeaderBack";
import { formatDate } from "../helper/helper";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAllTransactionsAction } from "../redux/action/metaBinAction";
import BarLoading from "../components/BarLoading";
import ErrorHandler from "../components/ErrorHandler";

function Transaction() {
  const dispatch = useDispatch();
  const { primary, secondary } = useSelector((state) => state.theme);
  const { loading, allTransaction, error } = useSelector(
    (state) => state.getTransactionStore
  );

  useEffect(() => {
    dispatch(getAllTransactionsAction());
  }, [dispatch]);

  if (error) {
    return (
      <div>
        <HeaderBack title={"Transaction"} />
        <ErrorHandler error={error} refresh={getAllTransactionsAction()} />
      </div>
    );
  }
  return (
    <div>
      <HeaderBack title={"Transaction"} />

      <div>
        {loading ? (
          <BarLoading />
        ) : (
          allTransaction.map((item, index) => {
            return (
              <div
                className="flex space-between p-1 align-center gap-1 border-bottom "
                key={index}
              >
                <div className="flex align-center gap-1 ">
                  <span
                    class="material-symbols-outlined   circle green-text "
                    style={{ backgroundColor: secondary, padding: "5px" }}
                  >
                    receipt_long
                  </span>
                  <div>
                    <b>Order ID {item._id}</b>
                    <p className="grey-text small-text ">
                      {" "}
                      {formatDate(item.date)}{" "}
                    </p>
                  </div>
                </div>

                <div>
                  <b>₹{item.amount}</b>
                  {item.approved ? (
                    <p
                      className="green white-text  small-text  "
                      style={{
                        borderRadius: "3px",
                        textAlign: "center",
                        padding: "0 5px"
                      }}
                    >
                      Paid
                    </p>
                  ) : (
                    <p
                      className="orange white-text  small-text  "
                      style={{
                        borderRadius: "3px",
                        textAlign: "center",
                        padding: "0 5px"
                      }}
                    >
                      Pending
                    </p>
                  )}
                </div>
              </div>
            );
          })
        )}
        {allTransaction.length === 0 && loading == false && (
          <div className="flex column align-center mt-4 ">
            <span
              className="material-symbols-outlined grey-text "
              style={{ fontSize: "10rem" }}
            >
              list_alt
            </span>
            <p className=" grey-text ">No history found. </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Transaction;
