import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../redux/action/auth";
import { Preloader, Checkbox } from "react-materialize";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function AuthStep1({ setAuthStep }) {
  const inputStyle = {
    border: "none",
    width: "84%",
    outline: "none",
    backgroundColor: "#fff",
    height: "54px",
    paddingLeft: "45px"
  };

  const dispatch = useDispatch();

  const { primary, secondary } = useSelector((state) => state.theme);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);

  const { showLoading } = useSelector((state) => state.loading);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (phoneNumber.length === 0) {
      return setPhoneNumberError("Enter phone number");
    } else if (phoneNumber.length < 10)
      return setPhoneNumberError("Invalid phone number");
    dispatch(registerUser(phoneNumber, setAuthStep));
  };

  return (
    <div className="white box p-1">
      <form>
        <p style={{ fontSize: "16px" }}>Enter Your Phone number</p>

        <div className="phone-input mt-1 ">
          <PhoneInput
            country={"in"}
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: true
            }}
            className="browser-default "
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e)}
            type="tel"
            inputStyle={inputStyle}
            dropdownStyle={{ backgroundColor: "#fff" }}
            buttonStyle={{
              backgroundColor: "#fff",
              border: "none",
              borderRight: "1px solid #ddd"
            }}
            containerStyle={{ backgroundColor: "#fff" }}
            placeholder="Enter phone number"
          />
        </div>
        {phoneNumberError && (
          <p className="small-text red-text ">{phoneNumberError}</p>
        )}
        <Checkbox
          checked={acceptTerms}
          onChange={() => setAcceptTerms(!acceptTerms)}
          filledIn={true}
          id="Checkbox_3"
          label="I Accept All Terms and Conditions."
          value={acceptTerms}
        />

        <button
          className="btn-submit mt-1 "
          style={{ backgroundColor: acceptTerms ? primary : "grey" }}
          type="submit"
          onClick={handleSubmit}
          disabled={acceptTerms ? false : true}
        >
          {!showLoading ? (
            "REQUEST OTP"
          ) : (
            <Preloader active color="#fff" flashing={false} size="small" />
          )}
        </button>
        <div className="flex"></div>
      </form>
    </div>
  );
}

export default AuthStep1;
