import React, { useEffect, memo } from "react";
import Header from "../components/Header";
import Frame from "../layout/Frame";
import { useSelector, useDispatch } from "react-redux";
import NearbyBins from "../components/NearbyBins";
import {
  getUserProfileAction,
  getAllRewardsAction
} from "../redux/action/metaBinAction";
import RecentActivity from "../components/RecentActivity";
import AppControl from "../components/AppControl";

function Home() {
  const MemoizedRecentActivity = memo(RecentActivity);
  const MemoizedAppControl = memo(AppControl);
  const dispatch = useDispatch();
  const { primary, secondary } = useSelector((state) => state.theme);

  useEffect(() => {
    dispatch(getAllRewardsAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);

  return (
    <Frame>
      <div className="flex  column align-center ">
        <Header backgroundColor="#ffffff" primaryColor={primary} />
        <MemoizedRecentActivity />
      </div>
      <MemoizedAppControl />
      <NearbyBins />
    </Frame>
  );
}

export default Home;
