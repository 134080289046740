import React, { useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import WarningPage from "./pages/WarningPage";
import Home from "./pages/Home";
import Congratulations from "./pages/Congratulations";
import ScanScreen from "./pages/ScanScreen";
import Rewards from "./pages/Rewards";
import History from "./pages/History";
import Account from "./pages/Account";
import EditProfile from "./pages/EditProfile";
import SearchBin from "./pages/SearchBin";
import Notifications from "./pages/Notifications";
import Maps from "./components/Maps";
import About from "./pages/About";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ManageAccount from "./pages/ManageAccount";
import Session from "./pages/Session";
import SingleBinScreen from "./pages/SingleBinScreen";
import AuthenticateUser from "./pages/AuthenticateUser";
import { ToastContainer } from "react-toastify";
import SingleRewardPage from "./pages/SingleRewardPage";
import StartUpPage from "./pages/StartUpPage";
import "react-toastify/dist/ReactToastify.css";
import LearnAboutMetaBin from "./pages/LearnAboutMetaBin";
import ReferAndEarn from "./pages/ReferAndEarn";
import { getUserLocation } from "./redux/action/metaBinAction";
import NotFound from "./pages/NotFound";
import SpinGame from "./pages/SpinGame";
import EnterUPIDetails from "./pages/EnterUPIDetails";
import SingleSession from "./pages/SingleSession";
import GameGongratulations from "./pages/GameGongratulations";
import ListOfBins from "./pages/ListOfBins";
import { motion, AnimatePresence } from "framer-motion";
import Transaction from "./pages/Transaction";
import AuthTest from "./pages/AuthTest";
import BarCodeInformation from './pages/BarCodeInformation'

function App() {
  const MemoizedHomePage = memo(Home);

  const dispatch = useDispatch();
  function getLocation() {
    navigator.geolocation.getCurrentPosition((position) => {
      const lat = position.coords.latitude;
      const log = position.coords.longitude;
      dispatch(getUserLocation(lat, log));
    });
  }

  useEffect(() => {
    getLocation();
  }, []);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  return isTabletOrMobile ? (
    <div>
      <ToastContainer />
      <Router>
        <AnimatePresence>
          <Routes>
            <Route path="/" element={<StartUpPage />} />
            <Route path="/:binId" element={<StartUpPage />} />
            <Route path="/auth/:referrer" element={<AuthenticateUser />} />
            <Route path="/auth" element={<AuthenticateUser />} />
            <Route path="/find-location" element={<Maps />} />
            <Route path="/list-of-bins" element={<ListOfBins />} />
            <Route path="/add-barcode" element={<BarCodeInformation />} />
            <Route path="/home" element={<MemoizedHomePage />} />
            <Route
              path="/learn-about-metabin"
              element={<LearnAboutMetaBin />}
            />
            <Route path="/refer-and-earn" element={<ReferAndEarn />} />
            <Route path="/rewards" element={<Rewards />} />
            <Route path="/enter-UPI-number" element={<EnterUPIDetails />} />
            <Route path="/history" element={<History />} />
            <Route path="/manage-account" element={<ManageAccount />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/account" element={<Account />} />
            <Route path="/edit-profile" element={<EditProfile />} />
            <Route path="/search-bin" element={<SearchBin />} />
            <Route path="/notification" element={<Notifications />} />
            <Route path="/session-ended/:id" element={<Congratulations />} />
            <Route path="/transaction" element={<Transaction />} />

            <Route path="/scan-screen" element={<ScanScreen />} />
            <Route path="/barcode-screen" element={<BarCodeInformation />} />

            <Route
              path="/game-gongratulations"
              element={<GameGongratulations />}
            />

            <Route path="/about-us" element={<About />} />
            <Route path="/session/:binId" element={<Session />} />
            <Route
              path="/single-session/:sessionID"
              element={<SingleSession />}
            />

            <Route
              path="/single-bin-screen/:id"
              element={<SingleBinScreen />}
            />
            <Route
              path="/singleRewardPage/:id"
              element={<SingleRewardPage />}
            />
            <Route path="/spin-game" element={<SpinGame />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AnimatePresence>
      </Router>
    </div>
  ) : (
    <WarningPage />
  );
}

export default App;
