import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BinProgressLine from "./BinProgressLine";

function SingleBin({ item }) {
  const dispatch = useDispatch();
  const { primary, secondary } = useSelector((state) => state.theme);

  return (
    <div className="white p-1 flex align-center mh-1 box mv-1">
      <img
        src="../images/bin.png"
        style={{ width: "50px ", height: "50px" }}
        alt="bin-image"
        loading="eager"
      />

      <div className="ml-1" style={{ width: "100%" }}>
        <div>
          <b>{item?.address?.split(" ").slice(0, 3).join(" ")}</b>

          <span className="flex align-center space-between ">
            <p className=" small-text " style={{ color: primary }}>
              {item.owner?.name}
            </p>
            <p className="grey-text">{item.fillLevel}% </p>
          </span>
        </div>

        <div className="">
          <BinProgressLine binFull={item.fillLevel} />
        </div>
      </div>
    </div>
  );
}

export default SingleBin;
