import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUserProfileAction } from "../redux/action/metaBinAction";

function HeaderBack({ title }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { primary } = useSelector((state) => state.theme);
  const { userProfile, loading } = useSelector(
    (state) => state.userProfileStore
  );

  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);

  return (
    <div
      className="full-width   p-1 flex align-center space-between "
      style={{ backgroundColor: primary }}
    >
      <div className="flex align-center ">
        <span
          onClick={() => navigate(-1)}
          role="button"
          className="material-symbols-outlined white-text "
          style={{ zIndex:"9999" }}
        >
          arrow_back
        </span>
        &nbsp;<p className="white-text">{title}</p>
      </div>

      <span
        className="flex align-center white justify-center "
        style={{ borderRadius: "3px", padding: "2px 10px" }}
        onClick={() => navigate("/history")}
      >
        <img
          src="../images/coin.png"
          style={{ width: "28px", height: "28px" }}
          alt="coin"
        />
        &nbsp;
        <b> {userProfile.cerc_coins ? userProfile.cerc_coins : 0}</b>
      </span>
    </div>
  );
}

export default HeaderBack;
