import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { accountInformationAction } from "../../redux/action/auth";
import { useNavigate } from "react-router-dom";
import { Preloader } from "react-materialize";
import { useParams } from "react-router-dom";

function UserInfo({ sessionId }) {
  const dispatch = useDispatch();
  const { referrer } = useParams();

  const { showLoading } = useSelector((state) => state.loading);

  const { primary, secondary } = useSelector((state) => state.theme);
  const [userName, setUserName] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userName === "") return setUserNameError("please enter your name.");
    dispatch(accountInformationAction(navigate, userName, sessionId, referrer));
  };

  return (
    <div className="white box p-1">
      <form>
        <p>Enter your name</p>
        <div className="input-field">
          <input
            className="browser-default "
            placeholder="Enter your name"
            type={"text"}
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
        </div>
        {userNameError && (
          <p className="small-text red-text">{userNameError}</p>
        )}

        <button
          className="btn-submit"
          type="submit"
          style={{ backgroundColor: primary }}
          onClick={handleSubmit}
        >
          {!showLoading ? (
            "confirm"
          ) : (
            <Preloader active color="#fff" flashing={false} size={"small"} />
          )}
        </button>
      </form>
    </div>
  );
}

export default UserInfo;
