import React from "react";
import BinProgressLine from "./BinProgressLine";

function SingleBinStatus({metaBin}) {
  return (
    <div className="flex  align-center column mv-1" >
      <b className="normal-size">Bin storage status</b>
      <b className="bold  orange-text mv-1 " style={{ fontSize:"3rem" }} >{metaBin.fillLevel}%</b>
      <b>FILLED</b>
      <BinProgressLine binFull={metaBin.fillLevel} />
    </div>
  );
}

export default SingleBinStatus;
