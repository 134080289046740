import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Frame from "../layout/Frame";
import { getUserProfileAction } from "../redux/action/metaBinAction";
import AccountHeader from "../components/account/AccountHeader";
import AccountSettings from "../components/account/AccountSettings";

function Account() {
  const dispatch = useDispatch();
const { userProfile } = useSelector((state)=> state.userProfileStore )
  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);

  return (
    <Frame>
      <AccountHeader />
      <AccountSettings />
      <div>
        <Link to="/manage-account">
          <div className="flex space-between p-1 mv-1  white border-bottom  ">
            <div className="flex align-center">
              <span className="material-symbols-outlined">manage_accounts</span>
              &nbsp;
              <b>Manage Account</b>
            </div>
            <span className="material-symbols-outlined">navigate_next</span>
          </div>
        </Link>

        <Link to="/transaction">
          <div className="flex space-between p-1 white mv-1  border-bottom">
            <div className="flex align-center">
              <span className="material-symbols-outlined primary-icon-color">
                receipt_long
              </span>
              &nbsp;
              <b>All Transactions</b>
            </div>
            <span className="material-symbols-outlined">navigate_next</span>
          </div>
        </Link>

        <Link to="/history">
          <div className="flex space-between p-1 white mv-1  border-bottom">
            <div className="flex align-center">
              <span className="material-symbols-outlined primary-icon-color">
                list_alt
              </span>
              &nbsp;
              <b>History and Activity</b>
            </div>
            <span className="material-symbols-outlined">navigate_next</span>
          </div>
        </Link>

        <Link to="/privacy-policy">
          <div className="flex space-between p-1 mv-1 white border-bottom  ">
            <div className="flex align-center">
              <span className="material-symbols-outlined primary-icon-color">
                lock
              </span>
              &nbsp;
              <b>Privacy Policy</b>
            </div>
            <span className="material-symbols-outlined">navigate_next</span>
          </div>
        </Link>

        <Link to="/about-us">
          <div className="flex space-between p-1 mv-1 white border-bottom  ">
            <div className="flex align-center">
              <span className="material-symbols-outlined primary-icon-color ">
                groups
              </span>
              &nbsp;&nbsp;
              <b>About us</b>
            </div>
            <span className="material-symbols-outlined">navigate_next</span>
          </div>
        </Link>
      </div>
    </Frame>
  );
}

export default Account;

// <div className="white-text">
// //   <h4>{userProfile.name}</h4>
// //   <p>{userProfile.email}</p>

// //   <Link to="/edit-profile">
// //     <button
// //       className="btn-small mt-1 "
// //       style={{ backgroundColor: "#fff", color: primary }}
// //     >
// //       Edit Profile
// //     </button>
// //   </Link>
// // </div>
