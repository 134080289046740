import React from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";

function Frame({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { primary, secondary } = useSelector((state) => state.theme);
  return (
    <div>
      <section
        style={{
          height: "100%",
          paddingBottom: "4.7rem",
          minHeight: "100vh"
        }}
      >
        {children}
      </section>
      <ul className="nav-bar">
        <li
          style={{
            backgroundColor: location.pathname === "/home" && secondary,
            color: location.pathname === "/home" && primary
          }}
          className={"center flex tab "}
        >
          <span
            className={`material-symbols-outlined ${
              location.pathname === "/home" && "icon"
            }`}
            onClick={() => navigate("/home")}
            role={"button"}
          >
            home
          </span>
        </li>

        <li
          style={{
            backgroundColor: location.pathname === "/list-of-bins" && secondary,
            color: location.pathname === "/list-of-bins" && primary,
            marginRight: "8px"
          }}
          className={"center flex tab  "}
          onClick={() => navigate("/list-of-bins")}
        >
          <span
            className={`material-symbols-outlined ${
              location.pathname === "/list-of-bins" && "icon"
            }`}
            role={"button"}
          >
            list_alt
          </span>
        </li>

        <li onClick={() => navigate("/scan-screen")}>
          <div id="container">
            <div
              className="item"
              style={{ position: "absolute", zIndex: 999, bottom: "20px" }}
            >
              <div
                className="scan-btn-home flex justify-center align-center"
                style={{ backgroundColor: primary }}
              >
                <span
                  className="material-symbols-outlined  white-text"
                  style={{ fontSize: "2.5rem" }}
                  role={"button"}
                >
                  qr_code_scanner
                </span>
              </div>
            </div>
          </div>
        </li>

        <li
          style={{
            backgroundColor: location.pathname === "/rewards" && secondary,
            color: location.pathname === "/rewards" && primary,
            marginLeft: "8px"
          }}
          className={"center flex tab  "}
          onClick={() => navigate("/rewards")}
        >
          <span
            className={`material-symbols-outlined ${
              location.pathname === "/rewards" && "icon"
            }`}
            role={"button"}
          >
            redeem
          </span>
        </li>

        <li
          style={{
            backgroundColor: location.pathname === "/account" && secondary,
            color: location.pathname === "/account" && primary
          }}
          className={"center flex tab "}
          onClick={() => navigate("/account")}
          role="button"
        >
          <span
            className={`material-symbols-outlined ${
              location.pathname === "/account" && "icon"
            }`}
            role={"button"}
          >
            person
          </span>
        </li>
      </ul>
    </div>
  );
}

export default Frame;
