export const getUserLocationReducer = (
  state = {
    loading: false,
    userLocation: {}
  },
  action
) => {
  switch (action.type) {
    case "GETTING_USER_LOCATION":
      return { ...state, loading: true };
    case "GOT_USER_LOCATION":
      return { ...state, loading: false, userLocation: action.payload };
    case "CANNOT_GET_LOCATION":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getClosestMetaBins = (
  state = { closestBins: [], loading: false, error: "" },
  action
) => {
  switch (action.type) {
    case "GETTING_CLOSEST_METABINS":
      return { ...state, loading: true };
    case "GOT_CLOSEST_METABINS":
      return { ...state, loading: false, closestBins: action.payload };
    case "CANNOT_GET_CLOSEST_BINS":
      return { ...state, loading: false, error: action.errorMessage };
    default:
      return state;
  }
};

export const getAllMetaBins = (
  state = { loading: false, listOfBins: [], error: "" },
  action
) => {
  switch (action.type) {
    case "GETTING_ALL_METABIN":
      return { ...state, loading: true };
    case "GOT_ALL_METABINS":
      return { ...state, loading: false, listOfBins: action.payload };
    case "CANNOT_GET_METABINS":
      return { ...state, error: action.errorMessage, loading: false };
    default:
      return state;
  }
};

export const getSingleMetaBinReducer = (
  state = { loading: false, metaBin: {} },
  action
) => {
  switch (action.type) {
    case "GETTING_SINGLE_METABIN":
      return { ...state, loading: true };
    case "GOT_SINGLE_METABIN":
      return { ...state, loading: false, metaBin: action.payload };
    case "CANNOT_GET_METABINS":
      return { ...state, error: action.errorMessage, loading: false };
    default:
      return state;
  }
};

export const getUserHistoryReducer = (
  state = { userHistory: [], loading: false, error: "" },
  action
) => {
  switch (action.type) {
    case "GETTING_USERHISTORY":
      return { ...state, loading: true };
    case "GOT_USER_HISTORY":
      return { ...state, loading: false, userHistory: action.payload };
    case "CANNOT_GET_USER_HISTORY":
      return { ...state, loading: false, error: action.errorMessage };
    default:
      return state;
  }
};

export const getUserProfile = (
  state = { userProfile: {}, loading: false },
  action
) => {
  switch (action.type) {
    case "GETTING_USER_PROFILE":
      return { ...state, loading: true };
    case "GOT_USERPRIFLE":
      return { ...state, loading: false, userProfile: action.payload };
    case "CANNOT_GET_USER_PROFILE":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getRewardsReducer = (
  state = {
    loading: false,
    rewards: [],
    error: ""
  },
  action
) => {
  switch (action.type) {
    case "GETTING_ALL_REWARDS":
      return { ...state, loading: true };
    case "GOT_REWARDS":
      return { ...state, loading: false, rewards: action.payload };
    case "CANNOT_GET_REWARDS":
      return { ...state, error: action.errorMessage, loading: false };
    default:
      return state;
  }
};

export const sessionReducer = (
  state = {
    loading: false,
    session: {},
    error: false
  },
  action
) => {
  switch (action.type) {
    case "STARTING_SESSION":
      return { ...state, loading: true, error: false };
    case "STARTED_SESSION":
      return { ...state, loading: false, session: action.payload };
    case "CANNOT_START_SESSION":
      return { ...state, loading: false, error: true };

    case "ENDING_SESSION":
      return { ...state, loading: true };
    case "ENDED_SESSION":
      return { ...state, loading: false, session: action.payload };
    case "CANNOT_END_SESSION":
      return { ...state, loading: false };
    case "GETTING_SESSION":
      return { ...state, loading: true };
    case "GOT_SESSION":
      return { ...state, loading: false, session: action.payload };
    case "CANNOT_GET_SESSION":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const notificationsReducer = (
  state = { loading: false, notifications: [], error: "" },
  action
) => {
  switch (action.type) {
    case "GETTING_NOTIFICATION":
      return { ...state, loading: true };
    case "GOT_NOTIFICATION":
      return { ...state, loading: false, notifications: action.payload };
    case "CANNOT_GET_NOTIFICATIONS":
      return { ...state, error: action.errorMessage, loading: false };
    default:
      return state;
  }
};

export const getBransReducer = (
  state = { loading: false, brands: [], error: "" },
  action
) => {
  switch (action.type) {
    case "GETTING_BRANDS":
      return { ...state, loading: true };
    case "GOT_ALL_BRANDS":
      return { ...state, loading: false, brands: action.payload };
    case "CANNOT_GET_BRANDS":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const spin2WinGamereducer = (
  state = { loading: false, gameResult: {}, error: "" },
  action
) => {
  switch (action.type) {
    case "ROTATION_NOW":
      return { ...state, loading: true };
    case "ROTATION_DONE":
      return { ...state, loading: false, gameResult: action.payload };
    case "CANNOT_RATATION":
      return { ...state, loading: false, error: action.errorMessage };
    default:
      return state;
  }
};

export const sendUPINumberReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case "SENDING_UPI_NUMBER":
      return { ...state, loading: true };
    case "UPI_NUMBER_SENT":
      return { ...state, loading: false };
    case "CANNOT_SEND_UPI_NUMBER":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getSingleSessionReducer = (
  state = { loading: false, sessionData: {} },
  action
) => {
  switch (action.type) {
    case "GETTING_SINGLE_SESSION":
      return { ...state, loading: true };
    case "GOT_SESSION_HISTORY":
      return { ...state, loading: false, sessionData: action.payload };
    case "CANNOT_GET_SINGLE_SESSION":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getTransactionReducer = (
  state = { loading: false, allTransaction: [], error: "" },
  action
) => {
  switch (action.type) {
    case "GETTING_TRANSACTIONS":
      return { ...state, loading: true };
    case "GOT_TRANSACTIONS":
      return { ...state, loading: false, allTransaction: action.payload };
    case "CANNOT_GET_TRANSACTIONS":
      return { ...state, loading: false, error: action.errorMessage };
    default:
      return state;
  }
};
