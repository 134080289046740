import React from "react";
import { Button } from "react-materialize";

function LoseGame() {
  return (
    <div className="flex column full-width align-center  ">
      {" "}
      <div className=" flex column align-center ">
        <b className="">Don't Worry,</b>
        <b className="normal-size cercle-purple-text ">
          You'll Get It Next Time!
        </b>
        <img src="../images/sad-emoji.png" style={{ width: "140px" }} />
      </div>
      <Button
        style={{
          backgroundColor: "#6f2da8",
          color: "#fff",
          fontSize: "16px"
        }}
        className="mt-2 flex gap-1 align-center "
        flat
        modal="close"
        node="button"
      >
        <div className="flex align-center ">
          <span className="material-symbols-outlined">arrow_back</span>
          <p>&nbsp;Spin again</p>
        </div>
      </Button>
    </div>
  );
}

export default LoseGame;
