import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthStep1 from "../components/auth/AuthStep1";
import ConfirmOTP from "../components/auth/ConfirmOTP";
import UserInfo from "../components/auth/UserInfo";

function AuthenticateUser() {
  const { primary, secondary ,brandDetails } = useSelector((state) => state.theme);
  const [authStep, setAuthStep] = useState(1);
  const { sessionId, phoneNumber } = useSelector(
    (state) => state.authenticateUser
  );
  return (
    <div
      className="authenticate-page pt-2"
      style={{
        backgroundColor: primary,
      }}
    >
      <div className="flex align-center column p-1 white-text">
        <b className="large-size">Metabin</b>
        { brandDetails.name ? <p>{brandDetails.name} in collaboration with Cercle X</p> : <p>Planet Warrior</p> }
      </div>

      <div>
        {authStep === 1 ? (
          <AuthStep1 setAuthStep={setAuthStep} />
        ) : authStep === 2 ? (
          <ConfirmOTP
            setAuthStep={setAuthStep}
            sessionId={sessionId}
            phoneNumber={phoneNumber}
          />
        ) : authStep === 3 ? (
          <UserInfo  sessionId={sessionId} />
        ) : (
          <></>
        )}
      </div>
      <div className="flex align-center justify-center white-text ">
        <p>Copyright © { new Date().getFullYear() } Cercle X. All rights reserved. </p>
      </div>
    </div>
  );
}

export default AuthenticateUser;
