import React from "react";
import ContentLoader from "react-content-loader";

function BarLoading(props) {
  return (
    <div className="m-1">
      {" "}
      <ContentLoader
        speed={2}
        width="100%"
        backgroundColor="#e6e6e6"
        foregroundColor="#ffffff"
        radius={"5px"}
        {...props}
      >
        <rect x="0" y="0" width="100%" height="55" rx="5" />
        <rect x="0" y="75" width="100%" height="55" rx="5" />
      </ContentLoader>
      <ContentLoader
        speed={2}
        width="100%"
        backgroundColor="#e6e6e6"
        foregroundColor="#ffffff"
        radius={"5px"}
        {...props}
      >
        <rect x="0" y="0" width="100%" height="55" rx="5" />
        <rect x="0" y="75" width="100%" height="55" rx="5" />
      </ContentLoader>
    </div>
  );
}
BarLoading.metadata = {
  name: "Bar Loading",
  github: "sridhareaswaran",
  description: "Meta Bin bar loading",
  filename: "TableLoader",
};

export default BarLoading;
