import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import congratulation from "../lotti/congratulation.json";
import { useLottie } from "lottie-react";

function GameGongratulations() {
  const { primary } = useSelector((state) => state.theme);
  const navigate = useNavigate();

  const options = {
    animationData: congratulation,
    loop: true
  };

  const { View } = useLottie(options);

  return (
    <div 
      className="page-bg flex column center pt-3 align-center "
      style={{ backgroundColor: primary, minHeight: "100vh" }}
    >
      <p className="white-text big-text ">Congrats!</p>
      <div style={{ width: "70%" }}>{View}</div>

      <>
        <div className="white-color ml-3 mt-2 mr-3">
          <p>
            Thank you for your participation and congratulations again on your
            win!
          </p>
          <h4></h4>
        </div>
        <div className="flex full-width center mt-3 space-between gap-2 pl-2 pr-2 ">
          <button
            className="btn-submit white-text "
            onClick={() => navigate("/home")}
            style={{ backgroundColor: primary, borderColor: "#fff" }}
          >
            Go Home
          </button>
        </div>
      </>
    </div>
  );
}

export default GameGongratulations;
