import React from "react";
import { Switch } from "react-materialize";


function NotificationSettings() {
  return (
    <div>
      <div className="flex space-between  ">
      <div className="flex column " > 
        <b>Phone Number Notifications</b>
        <p className="small-text" >Send All Notifications To Phone Number.</p>
        </div>
        <Switch
          id="Switch-20"
        />
      </div>

      <div className="flex space-between mt-2 ">
      <p>Email</p>
      <Switch
        id="Switch-21"
      />
    </div>
    </div>
  );
}

export default NotificationSettings;
