import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getColorTheme } from "../redux/action/theme";

function StartUpPage() {
  const dispatch = useDispatch();
  const { binId } = useParams();
  const { primary, secondary, brandDetails } = useSelector(
    (state) => state.theme
  );

  const navigate = useNavigate();

  const handleStart = async () => {
    try {
      const token = localStorage.getItem("METABIN_CCXT");
      if (token) {
        navigate("/home");
      } else {
        navigate("/auth");
      }
    } catch (error) {
      navigate("/auth");
    }
  };

  useEffect(() => {
    localStorage.setItem("primary-color", "#6f2da8");
    localStorage.setItem("secondary-color", "#f0f0f0");
  }, [dispatch, binId]);

  return (
    <div
      className="authenticate-page pt-2"
      style={{
        backgroundColor: primary
      }}
    >
      <div className="flex align-center column p-1 white-text">
        <b className="large-size" style={{ fontSize: "28px" }}>
          Metabin
        </b>
        {brandDetails.name ? (
          <p>{brandDetails.name} in collaboration with Cercle X</p>
        ) : (
          <p>Planet Warrior</p>
        )}
      </div>

      <div className="flex column space-between   " style={{ height: "80%" }}>
        <p></p>

        <button
          className="btn-submit"
          onClick={() => handleStart()}
          style={{ color: primary, backgroundColor: "#fff" }}
        >
          GET STARTED
        </button>
      </div>
      <div className="flex align-center justify-center white-text ">
        <p>
          Copyright © {new Date().getFullYear()} Cercle X. All rights reserved.{" "}
        </p>
      </div>
    </div>
  );
}

export default StartUpPage;
