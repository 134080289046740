import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { colorTheme } from "./reducer/theme";
import { loadingReducer } from "./reducer/loading";
import {
  getUserLocationReducer,
  getClosestMetaBins,
  getSingleMetaBinReducer,
  getUserHistoryReducer,
  getUserProfile,
  getRewardsReducer,
  sessionReducer,
  notificationsReducer,
  getBransReducer,
  spin2WinGamereducer,
  sendUPINumberReducer,
  getSingleSessionReducer,
  getTransactionReducer
} from "./reducer/metaBinReducer";
import { authenticateUserReducer, confirmOTPReducer } from "./reducer/auth";

const reducer = combineReducers({
  theme: colorTheme,
  loading: loadingReducer,
  userLocation: getUserLocationReducer,
  closestBins: getClosestMetaBins,
  authenticateUser: authenticateUserReducer,
  singleMetaBin: getSingleMetaBinReducer,
  userHistoryStore: getUserHistoryReducer,
  userProfileStore: getUserProfile,
  rewardsStore: getRewardsReducer,
  session: sessionReducer,
  listOfBrands: getBransReducer,
  notificationsList: notificationsReducer,
  spin2Win: spin2WinGamereducer,
  sendUPINumber: sendUPINumberReducer,
  getSingleSession: getSingleSessionReducer,
  confirmOTPStore: confirmOTPReducer,
  getTransactionStore: getTransactionReducer
});
const initialstate = {};
const middleware = [thunk];
const store = createStore(
  reducer,
  initialstate,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
