import React, { useEffect } from "react";
import Header from "../components/Header";
import Frame from "../layout/Frame";
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import RewardsList from "../components/RewardsList";
import { getUserProfileAction } from "../redux/action/metaBinAction";

function Rewards() {
  const dispatch = useDispatch();
  const { primary, secondary } = useSelector((state) => state.theme);
  const { userProfile, loading } = useSelector(
    (state) => state.userProfileStore
  );
  const { rewards } = useSelector((state) => state.rewardsStore);

  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);
  return (
    <Frame>
      <Header />
      <div
        className="center flex align-center  justify-center "
        style={{ backgroundColor: primary , paddingBottom:"22px"  }}
      >
        <Link to={"/rewards"}>
          <span className="flex column p-1 white-text ">
            <b className="large-size yellow-text ">
              {userProfile.cerc_coins ? userProfile.cerc_coins : 0}
            </b>
            <p className="">CERC Coins Earned</p>
          </span>
        </Link>
        <span className="line"></span>

        <Link to={"/history"}>
          <span className="flex column p-1 white-text ">
            <b className="large-size yellow-text ">
              {userProfile.productCount ? userProfile.productCount : 0}
            </b>
            <p className="">Items Dropped</p>
          </span>
        </Link>
        <span className="line"></span>
        <Link to={"/rewards"}>
          <span className="flex column p-1 white-text ">
            <b className="large-size yellow-text ">{rewards.length}</b>
            <p className="">Rewards Available</p>
          </span>
        </Link>
      </div>
      <RewardsList />
    </Frame>
  );
}

export default Rewards;
