import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function AccountSettings() {
  const { primary } = useSelector((state) => state.theme);
  const { userProfile } = useSelector((state) => state.userProfileStore);

  return (
    <div>
      <div
        style={{ backgroundColor: primary }}
        className="flex align-center space-between p-1"
      >
        <div className="flex align-center gap-1 ">
          <img
            src="../images/userImage.png"
            style={{ width: "60px", height: "60px" }}
            className="circle"
          />

          <div className="white-text">
            <b className="normal-size">{userProfile.name}</b>
            <p>{userProfile.email}</p>
          </div>
        </div>

        
      </div>
    </div>
  );
}

export default AccountSettings;


// <Link to="/edit-profile">
        //   <span class="material-symbols-outlined white-text ">
        //     border_color
        //   </span>
        // </Link>