import React from "react";

function WarningPage() {
  return (
    <div className="warning-page ">
      <div className="warning-content">
        <img
          src="../images/avatar.png"
          style={{ width: "11rem", marginTop: "-20rem" }}
        />
        <h1>Sorry</h1>
        <b>
          Our Citizenz Application can be only accessible through mobile
          devices.
        </b>
       
      </div>
    </div>
  );
}

export default WarningPage;
