import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Preloader } from "react-materialize";
import { useDispatch, useSelector } from "react-redux";
import UserLoading from "./UserLoading";

function Header({ backgroundColor, primaryColor }) {
  const dispatch = useDispatch();
  const { userLocation, loading } = useSelector((state) => state.userLocation);
  const { primary, secondary } = useSelector((state) => state.theme);

  console.log(userLocation)
  return (
    <header
      className="flex align-center space-between  full-width  pb-1 "
      style={{
        padding: "10px",
        backgroundColor: backgroundColor ? backgroundColor : primary
      }}
    >
      <div className="flex align-center ">
        <span
          className="material-symbols-outlined"
          role="button"
          style={{
            color: primaryColor ? primaryColor : "#fff",
            marginTop: "-5px"
          }}
        >
          location_on
        </span>
        &nbsp;
        {!loading ? (
          <p style={{ color: primaryColor ? primaryColor : "#fff" }}>
            {userLocation.plus_code?.compound_code
              ?.split(" ")
              .slice(1, 3)
              .join(" ")}
          </p>
        ) : (
          <UserLoading />
        )}
      </div>
      <div className=" flex align-center ">
        <Link
          to="/notification"
          style={{ color: primaryColor ? primaryColor : "#fff" }}
        >
          <span
            className="material-symbols-outlined"
            role={"button"}
            style={{ fontSize: "26px" }}
          >
            notifications
          </span>
          {/*<span className="notification-dot"></span>*/}
        </Link>
      </div>
    </header>
  );
}

export default Header;
