import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "../components/Header";
import HeaderBack from "../components/HeaderBack";
import BinProgressLine from "../components/BinProgressLine";
import { getSingleMetaBin } from "../redux/action/metaBinAction";
import BarLoading from "../components/BarLoading";
import SwipeButton from "../components/SwipeButton";
import ReactSwipeButton from "react-swipe-button";
import ErrorHandler from "../components/ErrorHandler";
import { Modal } from "react-materialize";
import SingleBinStatus from "../components/SingleBinStatus";
import SingleBinLocationDetails from "../components/SingleBinLocationDetails";

function SingleBinScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { primary, secondary } = useSelector((state) => state.theme);
  const { metaBin, loading, error } = useSelector(
    (state) => state.singleMetaBin
  );

  const handleNavigate = () => {
    const location = metaBin.address;
    const url = `https://www.google.com/maps?q=${location}`;
    window.location.href = url;
  };

  useEffect(() => {
    dispatch(getSingleMetaBin(id));
  }, [dispatch]);

  if (loading) {
    return (
      <div className="flex column">
        <HeaderBack title="Bin Details" />
        <BarLoading />
        <BarLoading />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <HeaderBack title="Bin Details" />
        <ErrorHandler error={error} />
      </div>
    );
  }

  return (
    <div className="flex column space-between  " style={{ height: "97vh" }}>
      <div>
        <HeaderBack title="Bin Details" />
        <div className="flex align-center space-between p-1 ">
          <p
            className="box small-text "
            style={{
              color: primary,
              padding: " 10px 12px",
              borderRadius: "5px",
              width: "100px",
              textAlign: "center"
            }}
          >
            {metaBin.material}&nbsp;Bin
          </p>
          <div className="flex">
            <p
              className={
                metaBin.status == "inactive"
                  ? " red lighten-5 lighten-1 white-text  small-size"
                  : "green  lighten-1 white-text small-size"
              }
              style={{ padding: "3px 10px", borderRadius: "3px" }}
            >
              {metaBin.status?.toUpperCase()}
            </p>
          </div>
        </div>

        <div>
          <div className="flex column align-center ">
            <img
              src="../images/METABIN.png"
              style={{ width: "200px", objectFit:"contain" }}
              alt="bin"
            />
          </div>
        </div>
        <div className="flex column align-center mt-1">
          <span className="flex space-between align-start gap-2  ">
            <div className="flex column ">
              <b>{metaBin.owner?.name}</b>
            </div>
          </span>
          <b
            className="large-size "
            style={{ fontWeight: "700", borderBottom: "2px solid #6CA77D" }}
          >
            Ready to use
          </b>
        </div>
      </div>

      <div className="flex align-center gap-2 space-between p-2  ">
        <Modal
          actions={[]}
          trigger={
            <button className="single-bin-btn bold large">
              {metaBin.fillLevel}%
            </button>
          }
          bottomSheet
          fixedFooter={false}
          id="bin_status_1"
        >
          <SingleBinStatus metaBin={metaBin} />
        </Modal>

        <button
          className="single-bin-scan-btn"
          onClick={() => navigate("/scan-screen")}
        >
          <span
            className="material-symbols-outlined "
            style={{ fontSize: "40px", color: primary }}
          >
            qr_code_scanner
          </span>
        </button>

        <Modal
          actions={[]}
          trigger={
            <button className="single-bin-btn">
              <span
                class="material-symbols-outlined  "
                style={{ fontSize: "2rem" }}
              >
                location_on
              </span>
            </button>
          }
          bottomSheet
          fixedFooter={false}
          id="bin_status_1"
        >
          <SingleBinLocationDetails
            metaBin={metaBin}
            handleNavigate={handleNavigate}
          />
        </Modal>
      </div>
    </div>
  );
}

export default SingleBinScreen;

// <div className="p-1 mt-1">
// <p className="mt-1 align-start flex ">
//   <span
//     className="material-symbols-outlined"
//     style={{ color: primary }}
//   >
//     location_on
//   </span>
//   {metaBin.detailedAddress}
//   {metaBin?.address}
// </p>

// <div className="mv-1 flex column align-end ">
//   <p className="grey-text">{metaBin.fillLevel}% Filled </p>
//   <BinProgressLine binFull={metaBin.fillLevel} />
// </div>

// <div className="flex mt-2 ">
//   <ReactSwipeButton
//     text={
//       <div className="swipe-btn">
//         <p></p>
//         <b>Swipe To Scan</b>
//         <img src="../images/logo.png" style={{ width: "35px" }} />
//       </div>
//     }
//     color={primary}
//     onSuccess={() => {
//       navigate("/scan-screen");
//     }}
//     swipeIcon={<img src="../images/logo.png" />}
//   />
// </div>

// <div
//   className=" flex align-end justify-end mt-4  "
//   onClick={handleNavigate}
//   role="button"
// >
//   <div
//     className="p-2  circle flex align-center justify-center "
//     style={{ width: "50px", height: "50px", backgroundColor: primary }}
//   >
//     <span
//       className="material-symbols-outlined large-size white-text "
//       role="button"
//     >
//       my_location
//     </span>
//   </div>
// </div>
// </div>
