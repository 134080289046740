export const authenticateUserReducer = (
  state = { sessionId: "", phoneNumber: "" },
  action
) => {
  switch (action.type) {
    case "CREATE_ACCOUNT_STEP1":
      return {
        ...state,
        sessionId: action.payload,
        phoneNumber: action.phoneNumber,
      };
    default:
      return state;
  }
};



export const confirmOTPReducer = (state={error:""},action)=>{
  switch(action.type){
    case "CANNOT_CONFIRM_OTP":
      return {...state,error:action.errorMessage}
    default:
      return state
  }
}