import React, { useEffect, useState } from "react";
import { Map, Marker, GoogleApiWrapper, InfoWindow } from "google-maps-react";
import HeaderBack from "./HeaderBack";
import { useSelector } from "react-redux";
import Sheet from "react-modal-sheet";
import SingleBin from "./SingleBin";
import { Link, useNavigate } from "react-router-dom";
import LocationLoading from "./LocationLoading";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { getClosestMetaBinsAction } from "../redux/action/metaBinAction";
import { getALLBrandsAction } from "../redux/action/metaBinAction";
import ErrorHandler from "./ErrorHandler";

export const MapContainer = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { brandDetails, materials } = useSelector((state) => state.theme);
  const { brands, error } = useSelector((state) => state.listOfBrands);
  const { primary } = useSelector((state) => state.theme);
  const { closestBins, loading } = useSelector((state) => state.closestBins);
  const [brandSelected, setBrandSelected] = useState(null);
  const [materialSelected, setMaterialSelected] = useState(null);
  const [openModal, setOpenModal] = useState(true);
  const [showResolt, setShowResolt] = useState(false);
  const [materialList, setMaterialList] = useState([]);
  const [brandsList, setBrandsList] = useState([]);
  const [log, setLog] = useState(null);
  const [lat, setLat] = useState(null);
  const [selectBrandError, setBrandSelectedError] = useState("");
  const [selectTypeError, setSelectTypeError] = useState("");

  function getLocation() {
    navigator.geolocation.getCurrentPosition((position) => {
      setLat(position.coords.latitude);
      setLog(position.coords.longitude);
    });
  }

  const state = {
    address: "",
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    mapCenter: {
      latitude: lat,
      longitude: log
    }
  };

  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "90vh",
    marginTop: "3px",
    boxShadow: "4px 4px 8px rgba(0,0,0,0.2),-4px -4px 8px rgba(255,255,255,1)"
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (brandSelected === null) {
      setBrandSelectedError("please select brand");
    } else if (materialSelected === null) {
      setSelectTypeError("please select type of waste");
    } else {
      setShowResolt(true);
      setOpenModal(false);
      dispatch(
        getClosestMetaBinsAction(lat, log, brandSelected, materialSelected)
      );
    }
  };

  useEffect(() => {
    if (brandDetails._id) {
      setBrandSelected(brandDetails._id);
    }

    if (!brandDetails._id) {
      const ownersList = brands.map((item) => ({
        value: item.ownerId,
        label: item.owner,
        materialsData: item.materials
      }));
      setBrandsList(ownersList);
      if (ownersList.length === 1) {
        setBrandSelected(ownersList[0].value);
      }
    }
    if (materials) {
      setMaterialSelected(materials[0]);
      const list = materials?.map((item) => ({ value: item, label: item }));
      setMaterialList(list);
    }
  }, [materials, brands]);

  useEffect(() => {
    const singleBrandMaterial = brandsList.map((item) => ({
      value: item.materialsData[0],
      label: item.materialsData[0]
    }));

    setMaterialList(singleBrandMaterial);
  }, [brandSelected]);

  useEffect(() => {
    dispatch(getALLBrandsAction());
    getLocation();
  }, []);

  return (
    <div>
      <div className="filter-icon" style={{ backgroundColor: primary }}>
        <span
          className="material-symbols-outlined  large-size "
          onClick={() => setOpenModal(!openModal)}
        >
          filter_list
        </span>
      </div>

      <HeaderBack title={"Metabin Location Map"} />
      <Map
        google={props.google}
        initialCenter={{
          lat: state.mapCenter.latitude,
          lng: state.mapCenter.longitude
        }}
        center={{
          lat: state.mapCenter.latitude,
          lng: state.mapCenter.longitude
        }}
        containerStyle={containerStyle}
      >
        {closestBins.map((bin, i) => {
          return (
            <Marker
              label="metabin"
              position={{
                lat: bin.location.coordinates[1],
                lng: bin.location.coordinates[0]
              }}
              icon={{
                url: "../images/bin_location.png",
                scaledSize: new window.google.maps.Size(40, 75),
                anchor: new window.google.maps.Point(32, 32)
              }}
              onClick={() => navigate(`/single-bin-screen/${bin._id}`)}
            />
          );
        })}
      </Map>

      <Sheet
        isOpen={openModal}
        onCloseEnd={() => setOpenModal(false)}
        onClose={() => setOpenModal(false)}
        snapPoints={[300, 300, 0]}
        initialSnap={1}
      >
        <Sheet.Container
          className="pt-1"
          style={{ backgroundColor: "#f0f0f0" }}
        >
          <Sheet.Header>
            <div className="flex  p-1 column  align-center  ">
              <span
                style={{
                  backgroundColor: "#999",
                  height: "5px",
                  borderRadius: "2px",
                  width: "60px"
                }}
                className="mv-1"
              ></span>
            </div>
          </Sheet.Header>
          <Sheet.Content>
            <form onSubmit={handleSubmit}>
              {brandsList?.length === 1 ? (
                <div>
                  <input
                    type="text"
                    placeholder={brandsList[0]?.label}
                    className="browser-default input "
                    disabled={brandsList?.length === 1}
                    value={brandsList[0]?.label}
                  />{" "}
                  {selectBrandError && (
                    <p className="red-text">{selectBrandError}</p>
                  )}
                </div>
              ) : (
                <div>
                  <Select
                    value={brandsList}
                    onChange={(e) => setBrandSelected(e.value)}
                    options={brandSelected}
                    onFocus={{ borderColor: "red" }}
                    className="mb-1"
                  />
                  {selectBrandError && (
                    <p className="red-text">{selectBrandError}</p>
                  )}{" "}
                </div>
              )}

              {materials?.length === 1 ? (
                <div>
                  {" "}
                  <input
                    type="text"
                    placeholder="Enter Material Type "
                    className="browser-default input "
                    disabled={materials.length === 1}
                    value={materials[0]}
                  />
                  {selectTypeError && (
                    <p className="red-text">{selectTypeError}</p>
                  )}
                </div>
              ) : (
                <div>
                  <Select
                  isClearable
                  isSearchable
                    placeholder={
                      materialSelected ? materialSelected : "select material..."
                    }
                    value={materialSelected}
                    onChange={ e => console.log(materialList) }
                    options={materialList}
                    onFocus={{ borderColor: "red" }}
                    className="mb-1"
                  />{" "}
                  {selectTypeError && (
                    <p className="red-text">{selectTypeError}</p>
                  )}{" "}
                </div>
              )}

              <button
                type="submit"
                className="btn full-width "
                style={{ backgroundColor: primary }}
              >
                Find Bins
              </button>
            </form>
          </Sheet.Content>
        </Sheet.Container>
      </Sheet>

      <Sheet
        isOpen={showResolt}
        onCloseEnd={() => setShowResolt(false)}
        onClose={() => setShowResolt(false)}
        snapPoints={[1000, 800, 400, 0]}
        initialSnap={1}
      >
        <Sheet.Container
          className="pt-1"
          style={{ backgroundColor: "#f0f0f0" }}
        >
          <Sheet.Header>
            <div className="flex  p-1 column  align-center  ">
              <span
                style={{
                  backgroundColor: "#999",
                  height: "5px",
                  borderRadius: "2px",
                  width: "50px"
                }}
                className="mv-1"
              ></span>
              <div className="flex space-between align-center full-width ">
                <b>List of Bins:</b>
                <b>{closestBins.length}&nbsp;Bins</b>
              </div>
            </div>
          </Sheet.Header>
          <Sheet.Content>
            {error && (
              <ErrorHandler error={error} refresh={getALLBrandsAction} />
            )}
            {loading && <LocationLoading />}
            {closestBins.map((item, index) => {
              return (
                <div>
                  <Link to={`/single-bin-screen/${item._id}`} key={index}>
                    <SingleBin item={item} />
                  </Link>
                  <span className="flex column align-center mt-1 ">
                    <p className="grey-text small-text ">
                      These are all the meta bins.
                    </p>
                  </span>
                </div>
              );
            })}
          </Sheet.Content>
        </Sheet.Container>
      </Sheet>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyDsVU8VuCB3p7REGp24SbXZnkNXHaIKCfo"
  // LoadingContainer: LoadingContainer
})(MapContainer);
