import React from "react";
import HeaderBack from "../components/HeaderBack";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function About() {
  const { primary } = useSelector((state) => state.theme);
  const navigate = useNavigate();
  return (
    <div className="page-bg">
      <div
        className="full-width   p-1 flex align-center space-between "
        style={{ backgroundColor: primary }}
      >
        <div className="flex align-center ">
          <span
            onClick={() => navigate(-1)}
            role="button"
            className="material-symbols-outlined white-text "
          >
            arrow_back
          </span>
          &nbsp;<p className="white-text">About Us</p>
        </div>
      </div>
      <div className="flex column center p-1 align-center ">
        <img src="../images/cerclex.png" className="logo" />
        <p className="mt-1">Infinite Cercle Private Limited (Cercle X)</p>
        <p className="blue-grey-text">Tech enabled waste management company</p>
        <div className="flex space-between mt-1">
          <div className="flex">
            <span
              className="material-symbols-outlined"
              style={{ color: primary }}
            >
              mail
            </span>
            <p>tech@cerclex.com</p>
          </div>
          &nbsp;
          <div className="flex">
            <span
              className="material-symbols-outlined"
              style={{ color: primary }}
            >
              call
            </span>
            <p>+919640496454</p>
          </div>
        </div>
        <p className="small-text mt-2 ">
          Infinite Cercle Private Limited is a cloud-based, fullstack waste
          management company, enabling brands to go zero waste to landfill and
          meet their sustainability goals.{" "}
        </p>
      </div>
    </div>
  );
}

export default About;
