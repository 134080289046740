import axios from "axios";
import { metaBinManagement } from "../config/configURL";
import { toast } from "react-toastify";

export const getColorTheme = (binId, primary) => async (dispatch) => {
  dispatch({ type: "GETTING_COLOR_THEME" });

  metaBinManagement
    .get(`/metabins/read/theme/${binId}`)
    .then((res) => {
      dispatch({
        type: "GOT_COLOR_THEME",
        primary: res.data.owner?.theme?.primaryColor,
        secondary: res.data.owner?.theme?.secondaryColor,
        brandDetails: res.data?.owner,
        materials: res.data?.materials
      });

      localStorage.setItem(
        "primary-color",
        res.data.owner?.theme?.primaryColor
      );
      localStorage.setItem(
        "secondary-color",
        res.data.owner?.theme?.secondaryColor
      );
      if (primary !== res.data.owner?.theme?.primaryColor) {
        window.location.reload();
      }
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_THEME" });
    });
};
