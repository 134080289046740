import React from "react";
import { useDispatch, useSelector } from "react-redux";

function ErrorHandler({ error, refresh }) {
  const dispatch = useDispatch();
  const { primary } = useSelector((state) => state.theme);

  function retryHandler() {
    dispatch(refresh);
  }

  return (
    <div
      className="flex column align-center justify-center "
      style={{ textAlign: "center" }}
    >
      <img src="../images/404.jpg" style={{ width: "80%" }} />
      <p>{error}</p>
      <b className=" mt-2" onClick={retryHandler} style={{ color: primary }}>
        RETRY
      </b>
    </div>
  );
}

export default ErrorHandler;
