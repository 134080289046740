import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function RecentActivity() {
  const { rewards } = useSelector((state) => state.rewardsStore);
  const { userProfile } = useSelector((state) => state.userProfileStore);
  const { primary } = useSelector((state) => state.theme);

  return (
    <div>
      <div
        className="center flex align-center home-counter justify-center "
        style={{ backgroundColor: primary }}
      >
        <Link to={"/rewards"}>
          <span className="flex column p-1 white-text ">
            <b className="large-size yellow-text ">
              {userProfile.cerc_coins ? userProfile.cerc_coins : 0}
            </b>
            <p className="">CERC Coins Earned</p>
          </span>
        </Link>

        <span className="line"></span>

        <Link to={"/history"}>
          <span className="flex column p-1 white-text ">
            <b className="large-size yellow-text ">
              {userProfile.productCount ? userProfile.productCount : 0}
            </b>
            <p className="">Items Dropped</p>
          </span>
        </Link>
        <span className="line"></span>

        <Link to="/rewards">
          <span className="flex column p-1 white-text ">
            <b className="large-size yellow-text ">{rewards.length}</b>
            <p className="">Rewards Available</p>
          </span>
        </Link>
      </div>
    </div>
  );
}

export default RecentActivity;
