import React from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function AppControl() {
  const navigate = useNavigate();
  const { primary, secondary } = useSelector((state) => state.theme);

  return (
    <div>
      <div className="flex column gap-1 ">
        <div
          className="flex align-center space-between white box "
          style={{ padding: "7px", margin: "0 10px" }}
          onClick={() => navigate("/learn-about-metabin")}
        >
          <div className="flex align-center ">
            <img
              src="../images/learn.png"
              alt="learn-about-bin"
              style={{ width: "50px", height: "50px" }}
              loading="eager"
            />
            <b>How to use this MetaBin App?</b>
          </div>
          <span
            className="material-symbols-outlined"
            style={{ color: primary }}
          >
            navigate_next
          </span>
        </div>
      </div>

      <Link to="/spin-game">
        <div className="mt-2" role={"button"}>
          <div
            className=" m-1 box p-1  "
            style={{
              position: "relative",
              height: "105px",
              background: "rgb(224,43,123)",
              background:
                "linear-gradient(90deg, rgba(224,43,123,1) 0%, rgba(249,83,53,1) 100%)"
            }}
          >
            <div className="flex column space-between  ">
              <b className="normal-size white-text ">SPIN THE WHEEL</b>
              <p className="small-text white-text ">
                Try Your Luck and Win Big!
              </p>
            </div>
            <img
              src="../images/spin-wheel.png"
              style={{
                width: "120px",
                position: "absolute",
                top: "-19px",
                right: "8px"
              }}
            />
          </div>
        </div>
      </Link>

      <div className="flex gap-1 align-center mt-1 mh-1 ">
        <Link
          to="/find-location"
          style={{ width: "49%" }}
          className="flex column align-center justify-center white box p-1 "
        >
          <span style={{ backgroundColor: secondary }} className="circle">
            <img
              src="../images/findBin.png"
              style={{ width: "65px", height: "58px", objectFit: "contain" }}
              alt="find-bin"
              loading="eager"
            />
          </span>
          <b>Find Bin</b>
          <b>Location</b>
        </Link>

        <Link
          to="/refer-and-earn"
          style={{ width: "49%" }}
          className="flex column circle  align-center justify-center white box p-1"
        >
          <span style={{ backgroundColor: secondary }} className="circle" >
            <img
              src="../images/earn.png"
              style={{ width: "65px", height: "58px", objectFit: "contain" }}
              alt="earn"
              loading="eager"
            />
          </span>
          <b>Refer and </b>
          <b>Earn Coins</b>
        </Link>
      </div>
    </div>
  );
}

export default AppControl;

// <div
// className="flex align-center space-between white box "
// style={{ padding: "7px", margin: "0 10px" }}
// onClick={() => navigate("/spin-game")}
// >
// <div className="flex align-center ">
//   <img
//     src="../images/spin-icon.png"
//     style={{ width: "50px", height: "50px" }}
//     alt="spin-icon"
//     loading="eager"
//   />
//   <div className="flex column ">
//     <b>Spin To Win</b>
//     <p className="small-text grey-text ">
//       Try Your Luck and Win Big!
//     </p>
//   </div>
// </div>
// <span
//   className="material-symbols-outlined"
//   style={{ color: primary }}
// >
//   navigate_next
// </span>
// </div>
