import React, { useEffect } from "react";
import Header from "../components/Header";
import Frame from "../layout/Frame";
import { useSelector, useDispatch } from "react-redux";
import { getUserHistoryAction } from "../redux/action/metaBinAction";
import { formatDate } from "../helper/helper";
import BarLoading from "../components/BarLoading";
import { useNavigate } from "react-router";
import ErrorHandler from "../components/ErrorHandler";

function History() {
  const navigate = useNavigate();
  const { primary } = useSelector((state) => state.theme);
  const { userHistory, loading, error } = useSelector(
    (state) => state.userHistoryStore
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserHistoryAction());
  }, [dispatch]);

  if (error) {
    return (
      <div>
        <Header />
        <ErrorHandler error={error} refresh={getUserHistoryAction()} />
      </div>
    );
  }

  return (
    <Frame>
      <div className="page-bg mb-4">
        <Header />
        {loading && <BarLoading />}
        {userHistory.length === 0 && loading == false && (
          <div className="flex column align-center mt-4 ">
            <span
              className="material-symbols-outlined grey-text "
              style={{ fontSize: "10rem" }}
            >
              list_alt
            </span>
            <p className=" grey-text ">No history found. </p>
          </div>
        )}
        {userHistory.map((item, index) => {
          return (
            <div
              key={index}
              className="flex space-between p-1 border-bottom  align-center"
              role={"button"}
              onClick={() => navigate(`/single-session/${item._id}`)}
            >
              <div className="flex ">
                &nbsp;
                <div>
                  <span
                    className="flex align-center space-between  "
                    style={{ width: "92vw" }}
                  >
                    <b style={{ color: primary }}>
                      {item.metabin?.owner?.name}
                    </b>

                    <p style={{ color: primary }}>
                      {formatDate(item.createdAt)}
                    </p>
                  </span>
                  <b>{item.productCount} item scanned</b>

                  <p>
                    {item.metabin.address?.split(" ").slice(0, 3).join(" ")}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Frame>
  );
}

export default History;
