import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Preloader } from "react-materialize";
import { confirmOTP, resendOTP } from "../../redux/action/auth";
import { useParams } from "react-router-dom";

function ConfirmOTP({ setAuthStep, phoneNumber, sessionId }) {
  const { primary } = useSelector((state) => state.theme);
  const { referrer } = useParams();
  const [timer, setTimer] = useState(60);
  const [isResending, setIsResending] = useState(false);
  const dispatch = useDispatch();
  const [OTP, setOTP] = useState("");
  const { showLoading } = useSelector((state) => state.loading);
  const { error } = useSelector((state) => state.confirmOTPStore);
  const [OTPError, setOTPError] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const [resendLoading, setResendLoading] = useState(false);

  const startTimer = () => {
    setIsResending(true);
    setTimer(60);

    if (timerId) {
      clearInterval(timerId);
    }

    const newTimerId = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimerId(newTimerId);
  };

  const handleResendClick = () => {
    dispatch(resendOTP(phoneNumber, startTimer, setResendLoading));
  };

  useEffect(() => {
    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [timerId]);

  useEffect(() => {
    startTimer();
  }, []);

  useEffect(() => {
    if (timer === 0) {
      setIsResending(false);
    }
  }, [timer]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (OTP === "" || OTP.length < 4)
      return setOTPError("OTP field cannot be empty.");
    dispatch(confirmOTP(phoneNumber, OTP, sessionId, setAuthStep, referrer));
  };

  return (
    <div className="white box p-1">
      <form onSubmit={handleSubmit} className="form">
        <p>Confirm Your OTP</p>
        {error && (
          <div
            className="red lighten-5 flex align-center justify-center "
            style={{ borderRadius: "5px", padding: "10px  7px" }}
          >
            <p className="red-text">{error}</p>
          </div>
        )}
        <input
          type="tel"
          maxLength="6"
          placeholder="Enter OTP"
          value={OTP}
          className="browser-default input mt-1"
          onChange={(e) => {
            setOTP(e.target.value);
            setOTPError("");
          }}
        />
        <div className="flex justify-end ">
          {isResending ? (
            <p className="flex small-text ">
              Try again in &nbsp; <p className="grey-text">00:{timer}</p>
            </p>
          ) : (
            <u
              onClick={handleResendClick}
              style={{ color: resendLoading ? "#999" : "#6f2da8" }}
            >
              Resend OTP
            </u>
          )}
        </div>
        {OTPError && <p className="small-text red-text">{OTPError}</p>}
        <button
          className="btn-submit mt-1 "
          type="submit"
          style={{ backgroundColor: primary }}
        >
          {!showLoading ? (
            "CONFIRM"
          ) : (
            <Preloader active color="#fff" flashing={false} size="small" />
          )}
        </button>
        <div className="flex"></div>
      </form>
    </div>
  );
}

export default ConfirmOTP;
