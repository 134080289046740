/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import HeaderBack from "../components/HeaderBack";
import myAudio from "../audio/game.ogg";
import gameAudio from "../audio/wingame.ogg";
import Celebration from "../components/Celebration";
import LoseGame from "../components/LoseGame";
import WinGame from "../components/WinGame";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "react-materialize";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../redux/action/auth";
import {
  spin2WinGameAction,
  getUserProfileAction
} from "../redux/action/metaBinAction";
import { metaBinManagement } from "../redux/config/configURL";
import { toast } from "react-toastify";
import ErrorHandler from "../components/ErrorHandler";

function SpinGame() {
  const navigate = useNavigate();
  const spinRef = useRef();
  const dispatch = useDispatch();
  const { primary, secondary } = useSelector((state) => state.theme);

  const { userProfile } = useSelector((state) => state.userProfileStore);
  const [loading, setLoading] = useState(false);

  const [showCelebration, setShowCelebration] = useState(false);
  const [gameResult, setGameResult] = useState("");
  const [showResult, setShowResult] = useState(false);

  function playAudio() {
    const audio = new Audio(myAudio);
    audio.play();
  }

  const handleSpin = async () => {
    playAudio();
    dispatch(spin2WinGameAction());
  };

  function playWinAudio() {
    const audio = new Audio(gameAudio);
    audio.play();
    setShowResult(true);
    setShowCelebration(true);
    setTimeout(() => {
      setShowCelebration(false);
    }, 3000);
  }

  const startTheGame = () => {
    setLoading(true);
    if (userProfile.cerc_coins < 3 || !userProfile) {
      toast.error("Sorry, no enough coins!");
    } else {
      metaBinManagement
        .put("/reward/update/spin-wheel")
        .then((res) => {
          setLoading(false);
          dispatch(getUserProfileAction());
          rotateRightAndLeft(res.data?.angle, res.data);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response && error.response.status === 502) {
            return dispatch({
              type: "CANNOT_RATATION",
              errorMessage:
                "We are having trouble connecting to our servers. Please try again later."
            });
          }

          if (
            (error.response && error.response.status === 404) ||
            (error.response && error.response.status === 400) ||
            (error.response && error.response.status === 401)
          ) {
            dispatch(logoutAction(navigate));
            window.location.href = "/auth";
          }

          if (!error.response) {
            return (
              <ErrorHandler
                error={error.response ? error.response?.data : error.message}
                refresh={startTheGame()}
              />
            );
          }
        });
    }
  };

  const stoppingDegrees = [
    22.5, 67.5, 112.5, 157.5, 202.5, 247.5, 292.5, 337.5
  ];

  const rotateRightAndLeft = async (stopDeg, res) => {
    spinRef.current.style.transition = "transform 3s";
    spinRef.current.style.transform = "rotate(1420deg)";
    setTimeout(() => {
      spinRef.current.style.transition = "transform 1.5s";
      spinRef.current.style.transform = `rotate(${-stopDeg}deg)`;
    }, 3000);
    setTimeout(() => {
      if (res.won) {
        navigate("/enter-UPI-number", { state: { res } });
      } else {
        setShowResult(true);

        setGameResult(res);
      }
    }, 6000);
  };

  return (
    <div>
      <HeaderBack title="spin to win" />
      <Modal
        actions={[]}
        bottomSheet={false}
        fixedFooter={false}
        header=""
        id="Modal-10"
        open={showResult}
        options={{
          onCloseEnd: () => setShowResult(false)
        }}
      >
        {!gameResult?.won ? (
          <LoseGame />
        ) : (
          navigate("/enter-UPI-number", { state: { res: gameResult } })
        )}
      </Modal>
      {showCelebration && <Celebration />}
      <div className="flex column align-center pt-1 game">
        <img
          src="../images/spintowin.png"
          style={{ width: "100vw", height: "110px", objectFit: "contain" }}
          loading="lazy"
        />

        <div
          className="flex align-center justify-center mt-3"
          style={{ position: "relative" }}
        >
          <img
            src="../images/polygon.png"
            style={{
              width: "40px",
              height: "45px",
              position: "absolute",
              top: "-20px",
              zIndex: "2"
            }}
          />

          <img
            ref={spinRef}
            src="../images/spin.png"
            style={{ width: "85vw", height: "85vw", objectFit: "contain" }}
          />
        </div>

        {userProfile.cerc_coins < 3 ? (
          <b
            className="white-text  mt-1 small-text red box lighten-1"
            style={{ padding: "2px 7px " }}
          >
            You don't have enough coins.
          </b>
        ) : (
          <b className="white-text  mt-1 small-text ">
            Use your coins to play now.
          </b>
        )}

        <button
          onClick={() => {
            if (!loading) {
              startTheGame();
            }
          }}
          className="mt-1 spin-btn "
          style={{
            backgroundColor: `${userProfile.cerc_coins < 3 ? "grey" : primary}`,
            color: `${userProfile.cerc_coins < 3 ? "#fff" : "gold"}`
          }}
          disabled={userProfile.cerc_coins < 3}
        >
          SPIN
        </button>
      </div>
    </div>
  );
}

export default SpinGame;
