import { getAccountInfo } from "../redux/action/auth";
import React from "react";
import { useLocation } from "react-router-dom";

export function handleReload() {
  if (localStorage.getItem("CCXT")) getAccountInfo();
}

export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const formatDate = (date) => {
  const createdAt = new Date(date);
  const formattedDate = createdAt.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });
  return formattedDate;
};
