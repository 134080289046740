import React, { useState } from "react";
import HeaderBack from "../components/HeaderBack";
import { toast } from "react-toastify";
import { Button, Modal } from "react-materialize";
import { useSelector } from "react-redux";
import { useEffect } from "react";

function ReferAndEarn() {
  const { primary, secondary } = useSelector((state) => state.theme);

  const [visible, setVisible] = useState(false);
  const { userProfile, loading } = useSelector(
    (state) => state.userProfileStore
  );

  const sendInvite = (app) => {
    const message = `Hey! I wanted to let you know about this new app called MetaBins that I've been using. 
    It's all about smart bins - you can use it to find nearby bins, check their availability, 
    and even earn rewards for using them. Plus, if you sign up using my referral link https://app.metabin.thinktrash.co/auth/referrer=${userProfile._id},
     you'll get 3 CREC Coins to get started. Let me know what you think!`;

    if (app === "facebook") {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${"https://app.metabin.thinktrash.co/"}&quote=${"message"}`,
        "fbshare",
        "height=450,width=550"
      );
    } else if (app === "twitter") {
      window.open(
        `https://twitter.com/intent/tweet?text=${"message"}&url=${"referralLink"}`,
        "twshare",
        "height=450,width=550"
      );
    } else if (app === "mail") {
      window.location.href = `mailto:?subject=Join app and earn coins!&body=${message}`;
    } else if (app === "whatsapp") {
      window.open(`https://api.whatsapp.com/send?text=${message}`);
    }
  };
  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(
        `https://app.metabin.thinktrash.co/auth/referrer=${userProfile._id}`
      );
      handleCopy();
    } catch (error) {
    }
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setVisible(false);
    }, 2000);

    return () => {
      clearTimeout(timeOut);
    };
  }, [visible]);

  const handleCopy = () => {
    setVisible(true);
  };

  return (
    <div>
      <HeaderBack title={"Refer & Earn"} />
      <div
        className="flex column align-center  p-2  "
        style={{ textAlign: "center" }}
      >
        <img
          src="../images/refer.png"
          style={{ width: "70vw", objectFit: "contain", height: "140px" }}
          className=" mb-2 "
        />

        <b className="normal-size" style={{ color:primary }}>Get 3 CERC Coin</b>
        <b style={{ fontSize: "16px", fontWeight: "bold" }}>
          By inviting your friends to join our app.
        </b>
        <b className="grey-text small-text mt-1 ">
          Share the link with your friends through email, social media, or any
          other way you like.
        </b>

        <div
          className="flex align-center grey-text copy-btn flex align-center justify-center mt-2"
          onClick={handleCopyLink}
          role="button"
        >
          <span className="material-symbols-outlined">content_copy</span>
          <p>&nbsp;Copy invite link</p>
        </div>
        {visible && (
          <p className="small-text green-text mt-1 ">
            Your referral link has been copied.
          </p>
        )}

        <span
          className="grey mv-2 "
          style={{ height: "0.09px", width: "80vw" }}
        ></span>
        <b className="normal-size  " style={{ color:primary }} >Get 3 CERC Coin</b>
        <b style={{ fontSize: "16px", fontWeight: "bold" }}>
          For any account you connect
        </b>
        <div></div>
        <Modal
          bottomSheet
          fixedFooter={false}
          header="Invite Friends:"
          id="Modal-11"
          open={false}
          options={{}}
          trigger={
            <button
              node="button"
              className="mt-1 primary-btn flex align-center justify-center gap-1 "
              style={{ backgroundColor:primary }}
            >
              <span className="material-symbols-outlined">share</span>
              <b>SHARE</b>
            </button>
          }
        >
          <div
            className="box  p-1 flex align-center space-between  "
            style={{ padding: "15px 20px " }}
          >
            <div
              className="flex column align-center justify-center "
              role={"button"}
              onClick={() => sendInvite("facebook")}
            >
              <img src="../images/facebook.png " style={styles.icon} />
              <b className="small-text">Facebook</b>
            </div>

            <div
              className="flex column align-center justify-center "
              role={"button"}
              onClick={() => sendInvite("twitter")}
            >
              <img src="../images/twitter.png " style={styles.icon} />
              <b className="small-text">Twitter</b>
            </div>

            <div
              className="flex column align-center justify-center "
              role={"button"}
              onClick={() => sendInvite("whatsapp")}
            >
              <img src="../images/whatsapp.jpg " style={styles.icon} />
              <b className="small-text">Whatsapp</b>
            </div>

            <div
              className="flex column align-center justify-center"
              role={"button"}
              onClick={() => sendInvite("mail")}
            >
              <img src="../images/mail.png" style={styles.icon} />
              <b className="small-text">Email</b>
            </div>
          </div>
          <Button
            className="outline-btn white t mt-1  "
            modal="close"
            node="button"
            waves="green"
            style={{ border: `1px solid ${primary}`, width: "100%", color:primary }}
          >
            Close
          </Button>
        </Modal>
      </div>
    </div>
  );
}
const styles = {
  icon: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    objectFit: "contain"
  }
};
export default ReferAndEarn;

// <b className="grey-text">
// Sorry, the refer and earn feature is currently unavailable. Please try
// again later.
// </b>
