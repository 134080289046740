import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getClosestMetaBinsAction } from "../redux/action/metaBinAction";
import SingleBin from "../components/SingleBin";
import BarLoading from "../components/BarLoading";
import Frame from "../layout/Frame";
import Header from "../components/Header";
import { motion } from "framer-motion";
import { MOTION_VARIANTS } from "../helper/constants";
import ErrorHandler from "../components/ErrorHandler";

function ListOfBins() {
  const dispatch = useDispatch();
  const { closestBins, loading, error } = useSelector(
    (state) => state.closestBins
  );

  function getClosestMetaBins() {
    navigator.geolocation.getCurrentPosition((position) => {
      const lat = position.coords.latitude;
      const log = position.coords.longitude;
      dispatch(getClosestMetaBinsAction(lat, log));
    });
  }

  useEffect(() => {
    getClosestMetaBins();
  }, []);

  if (error) {
    const lat = "";
    const log = "";
    navigator.geolocation.getCurrentPosition((position) => {
      lat = position.coords.latitude;
      // eslint-disable-next-line no-const-assign
      log = position.coords.longitude;
    });
    return (
      <div>
        <Header />
        <ErrorHandler
          error={error}
          refresh={getClosestMetaBinsAction(lat, log)}
        />
      </div>
    );
  }

  return (
    <Frame>
      <Header />
      <motion.div
        custom={{ direction: "forward" }}
        initial="initial"
        animate="in"
        exit="out"
        variants={MOTION_VARIANTS}
      >
        <div className="mt-1 mb-3 ">
          {loading && <BarLoading />}
          {closestBins.length === 0 || !closestBins ? (
            <div className="flex column align-center mt-2 ">
              <img
                src="../images/emptyBin.png"
                style={{ width: "100px", height: "100px" }}
                alt="emptyBin"
                loading="lazy"
              />
              <p className="grey-text">Sorry you have no bins near you.</p>
            </div>
          ) : (
            <div>
              {" "}
              {closestBins?.map((item, index) => {
                return (
                  <div key={index}>
                    <Link to={`/single-bin-screen/${item._id}`}>
                      <SingleBin item={item} />
                    </Link>
                  </div>
                );
              })}
              <span className="flex column align-center mt-1 ">
                <p className="grey-text small-text">
                  These are all the meta bins near your location.
                </p>
              </span>
            </div>
          )}
        </div>
      </motion.div>
    </Frame>
  );
}

export default ListOfBins;
