import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Celebration from "../components/Celebration";
import { useDispatch, useSelector } from "react-redux";
import { startSession, endSessionAction } from "../redux/action/metaBinAction";
import { useNavigate, useParams } from "react-router-dom";
import { socket } from "../redux/config/socket";
import { Preloader } from "react-materialize";
import { getColorTheme } from "../redux/action/theme";

function Session() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { primary, secondary } = useSelector((state) => state.theme);
  const { binId } = useParams();
  const [showCelebration, setShowCelebration] = useState(false);
  const [productCount, setProductCount] = useState(0);
  const endSession = () => {
    dispatch(endSessionAction(binId, navigate));
  };

  const { session, loading, error } = useSelector((state) => state.session);

  useEffect(() => {
    const token = localStorage.getItem("METABIN_CCXT");
    if (!token) {
      navigate("/");
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(startSession(binId));

    if (binId) {
      dispatch(getColorTheme(binId, primary));
    } else {
      localStorage.setItem("primary-color", "#6f2da8");
      localStorage.setItem("secondary-color", "#f0f0f0");
    }
  }, [dispatch, binId, primary]);

  useEffect(() => {
    socket.on("product_dropped", (count) => {
      setShowCelebration(true);
      setProductCount(count);
      setTimeout(() => {
        setShowCelebration(false);
      }, 3000);
    });
  }, []);

  if (error)
    return (
      <>
        <Header />
        <div
          className="flex  align-center  pb-2 ph_1 column "
          style={{ height: "85vh", backgroundColor: primary }}
        >
          <div
            className="flex justify-center"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            <div className="flex column align-center p-2 ">
              <img
                src="../images/bin.png"
                style={{ objectFit: "contain", width: "150px" }}
                alt="emptyBin"
              />
              <h4 className="white-text">Metabin Offline</h4>
              <p className="white-text normal-size mt-1 text-center">
                It looks like this MetaBin is offline at the moment, please use
                another bin or try again later
              </p>
            </div>
          </div>
          <button
            className="btn-submit "
            style={{ color: primary }}
            onClick={() => dispatch(startSession(binId))}
          >
            Retry Connecting
          </button>
        </div>
      </>
    );

  return (
    <div>
      <Header />
      {loading ? (
        <div
          className="flex  align-center  pb-2 ph_1 column "
          style={{ height: "95vh", backgroundColor: primary }}
        >
          <div
            className="flex justify-center"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            <Preloader active color={primary} />
          </div>
        </div>
      ) : (
        <div
          className="flex  align-center  pb-2 ph_1 column "
          style={{ height: "95vh", color: primary, backgroundColor: "#ecf0f3" }}
        >
          <div className="flex column align-center ">
            <b className="normal-size  mt-1 mb-1 ">
              Drop products into the bin
            </b>

            <div className="flex column align-center ">
              {showCelebration && <Celebration />}
              <span className="neumorphism">
                <div className="no-bottel  ">
                  <b>{productCount}</b>
                </div>
              </span>

              <b className="normal-size  center-align">
                You've dropped {productCount} bottles into the Metabin!
              </b>
              <b className="normal-size  center-align black-text ">
                Scan and drop more bottles to earn more CERC Coins.
              </b>

              <div className="flex align-center mt-2  ">
                <div className=" flex column align-center p-1  normal-size ">
                  <p>CERC Coins Earned</p>
                  <h2>{productCount * 3}</h2>
                </div>
                {/* <span className="line"></span>
              <div className=" flex column align-center p-1  normal-size ">
                <p>Rewards</p>
                <h2>20</h2>
              </div> */}
              </div>
            </div>
          </div>

          <button
            className="btn-submit  "
            style={{ backgroundColor: primary }}
            onClick={endSession}
          >
            End Session
          </button>
        </div>
      )}
    </div>
  );
}

export default Session;
