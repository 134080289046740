import React from "react";
import { useSelector } from "react-redux";

function SingleBinLocationDetails({ metaBin, handleNavigate }) {
  const { primary } = useSelector((state) => state.theme);

  return (
    <div>
      <span className="flex justify-center mb-1">
        <b className="bold large-size ">Location Details</b>
      </span>
      <span style={{ color: primary }} className="flex align-center">
        <span class="material-symbols-outlined" role="button">
          location_on
        </span>
        <p>{metaBin.address}</p>
      </span>
      <p>{metaBin.detailedAddress}</p>

      <span className="flex align-center justify-center mt-3 ">
        <button className="btn" onClick={handleNavigate} role="button">
          Get Direction
        </button>
      </span>
    </div>
  );
}

export default SingleBinLocationDetails;
