import React from "react";
import { useLocation } from "react-router-dom";
import HeaderBack from "../components/HeaderBack";
import { useSelector } from "react-redux";

function SingleRewardPage() {
  const location = useLocation();
  const { item } = location.state;
  const { primary, secondary } = useSelector((state) => state.theme);

  return (
    <div>
      <HeaderBack title={"Rewards Detail"} />

      <div className="flex column  m-1 ">
        <img src="../images/3.png" style={{ width: "100%", borderRadius:"10px" }} alt='single-screen-img'  />

        <span className="flex space-between mv-1 ">
          <b className="normal-size">{item.name}</b>
          <b className="yellow-text text-accent-4 normal-size ">
            {item.pointsRequired} CERC{" "}
          </b>
        </span>
        <b>Details:</b>
        <p>{item.description}</p>
        <button
          className="btn-submit mt-5"
          style={{
            borderColor: "grey",
            backgroundColor: primary,

            
          }}
          disabled={true}
        > <img src="../images/coin.png" style={{ width:"22px", height:"22px" }} alt='coin'    />&nbsp;
          Redeem using {item.pointsRequired} CERC coins
        </button>
      </div>
    </div>
  );
}

export default SingleRewardPage;
