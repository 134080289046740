const primaryColor = localStorage.getItem("primary-color");
const secondaryColor = localStorage.getItem("secondary-color");

export const colorTheme = (
  state = {
    primary: primaryColor ? primaryColor : "#6f2da8 ",
    secondary: secondaryColor ? secondaryColor : "#f0f0f0",
    brandDetails: {},
    material: [],
  },
  action
) => {
  switch (action.type) {
    case "GETTING_COLOR_THEME":
      return { ...state, loading: true };
    case "GOT_COLOR_THEME":
      return {
        ...state,
        loading: false,
        primary: primaryColor,
        secondary: secondaryColor,
        materials: action.materials,
        brandDetails: action.brandDetails,
      };
    case "CANNOT_GET_THEME":
      return { ...state, loading: false };
    default:
      return state;
  }
};
