import axios from "axios";
import { toast } from "react-toastify";
import { showLoading, hideLoading } from "./loading";
import { metaBinManagement, metaBin } from "../config/configURL";
import { logoutAction } from "./auth";

export const getUserLocation = (lat, lng) => async (dispatch) => {
  console.log(lat, lng)
  dispatch({ type: "GETTING_USER_LOCATION" });
  axios
    .get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDsVU8VuCB3p7REGp24SbXZnkNXHaIKCfo`
    )
    .then((res) => {
      dispatch({ type: "GOT_USER_LOCATION", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_LOCATION" });
    });
};

export const getAllMetaBins = () => async (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: "GETTING_ALL_METABIN" });
  metaBin
    .get("/metabins/read")
    .then((res) => {
      dispatch({ type: "GOT_ALL_METABINS", payload: res.data });
      dispatch(hideLoading());
    })
    .catch((error) => {
      dispatch(hideLoading());
    });
};

export const getSingleMetaBin = (id) => async (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: "GETTING_SINGLE_METABIN" });
  metaBinManagement
    .get(`/metabins/read/${id}`)
    .then((res) => {
      dispatch({ type: "GOT_SINGLE_METABIN", payload: res.data });
      dispatch(hideLoading());
    })
    .catch((error) => {
      if (error.response && error.response.status === 502) {
        return dispatch({
          type: "CANNOT_GET_METABINS",
          errorMessage:
            "We are having trouble connecting to our servers. Please try again later."
        });
      }

      if (
        (error.response && error.response.status === 404) ||
        (error.response && error.response.status === 400) ||
        (error.response && error.response.status === 401)
      ) {
        dispatch(logoutAction());
        window.location.href = "/auth";
        return dispatch({
          type: "CANNOT_GET_METABINS",
          errorMessage: "Please login again to continue."
        });
      }

      if (!error.response) {
        return dispatch({
          type: "CANNOT_GET_METABINS",
          errorMessage: "Please check your internet connection and try again."
        });
      }
    });
};

export const getClosestMetaBinsAction =
  (lat, log, organization, material) => async (dispatch) => {
    let URL = `metabins/read/closest?latitude=${lat}&longitude=${log}`;
    if (organization && material) {
      URL += `&organization=${organization}&material=${material}`;
    }
    dispatch({ type: "GETTING_CLOSEST_METABINS" });
    metaBinManagement
      .get(URL)
      .then((res) => {
        dispatch({ type: "GOT_CLOSEST_METABINS", payload: res.data });
        dispatch(hideLoading());
      })
      .catch((error) => {
        if (error.response && error.response.status === 502) {
          return dispatch({
            type: "CANNOT_GET_CLOSEST_BINS",
            errorMessage:
              "We are having trouble connecting to our servers. Please try again later."
          });
        }

        if (
          (error.response && error.response.status === 404) ||
          (error.response && error.response.status === 400) ||
          (error.response && error.response.status === 401)
        ) {
          dispatch(logoutAction());
          window.location.href = "/auth";
          return dispatch({
            type: "CANNOT_GET_CLOSEST_BINS",
            errorMessage: "Please login again to continue."
          });
        }

        if (!error.response) {
          return dispatch({
            type: "CANNOT_GET_CLOSEST_BINS",
            errorMessage: "Please check your internet connection and try again."
          });
        }
      });
  };

// handler
export const getMetaBinSessions = (binID) => async (dispatch) => {
  dispatch(showLoading());
  metaBinManagement
    .get(`session/read/all/${binID}`)
    .then((res) => {
      dispatch({ type: "GOT_METABIN_SESSIONS", payload: res.data });
      dispatch(hideLoading());
    })
    .catch((error) => {
      // toast.error(error.response ? error.response?.data : error.message);
    });
};

export const getSession = (sessionID) => async (dispatch) => {
  if (!sessionID) return;
  dispatch({ type: "GETTING_SESSION" });
  metaBinManagement
    .get(`/session/read/single/${sessionID}`)
    .then((res) => {
      dispatch({ type: "GOT_SESSION", payload: res.data });
    })
    .catch((error) => {
      // toast.error(error.response ? error.response?.data : error.message);
    });
};

export const startSession = (metaBinID) => async (dispatch) => {
  dispatch({ type: "STARTING_SESSION" });

  metaBin
    .post(`/session/create/start/${metaBinID}`)
    .then((res) => {
      dispatch(hideLoading());
      dispatch({ type: "STARTED_SESSION", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_START_SESSION" });
    });
};

export const endSessionAction = (metabinId, navigate) => async (dispatch) => {
  dispatch({ type: "ENDING_SESSION" });
  metaBin
    .put(`session/update/end/${metabinId}`)
    .then((res) => {
      dispatch({ type: "ENDED_SESSION", payload: res.data });
      navigate("/session-ended/" + res.data._id);
    })
    .catch((error) => {
      if (error.response && error.response.status === 502) {
        return dispatch({
          type: "CANNOT_END_SESSION",
          errorMessage:
            "We are having trouble connecting to our servers. Please try again later."
        });
      }

      if (
        (error.response && error.response.status === 404) ||
        (error.response && error.response.status === 400) ||
        (error.response && error.response.status === 401)
      ) {
        dispatch(logoutAction());
        window.location.href = "/auth";
        return dispatch({
          type: "CANNOT_END_SESSION",
          errorMessage: "Please login again to continue."
        });
      }

      if (!error.response) {
        return dispatch({
          type: "CANNOT_END_SESSION",
          errorMessage: "Please check your internet connection and try again."
        });
      }
      dispatch({ type: "CANNOT_END_SESSION" });
      // toast.error(error.response ? error.response?.data : error.message);
    });
};

export const getUserHistoryAction = () => async (dispatch) => {
  dispatch({ type: "GETTING_USERHISTORY" });

  metaBinManagement
    .get("/session/read/my-history")
    .then((res) => {
      dispatch({ type: "GOT_USER_HISTORY", payload: res.data });
    })
    .catch((error) => {
      if (error.response && error.response.status === 502) {
        return dispatch({
          type: "CANNOT_GET_USER_HISTORY",
          errorMessage:
            "We are having trouble connecting to our servers. Please try again later."
        });
      }

      if (
        (error.response && error.response.status === 404) ||
        (error.response && error.response.status === 400) ||
        (error.response && error.response.status === 401)
      ) {
        dispatch(logoutAction());
        window.location.href = "/auth";
        return dispatch({
          type: "CANNOT_GET_USER_HISTORY",
          errorMessage: "Please login again to continue."
        });
      }

      if (!error.response) {
        return dispatch({
          type: "CANNOT_GET_USER_HISTORY",
          errorMessage: "Please check your internet connection and try again."
        });
      }
    });
};

export const getUserProfileAction = () => async (dispatch) => {
  dispatch({ type: "GETTING_USER_PROFILE" });
  metaBinManagement
    .get("/profile/read/my-profile")
    .then((res) => {
      console.log(res.data);
      dispatch({ type: "GOT_USERPRIFLE", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_USER_PROFILE" });

      if (error.response && error.response.status === 502) {
        return dispatch({
          type: "GETTING_USER_PROFILE",
          errorMessage:
            "We are having trouble connecting to our servers. Please try again later."
        });
      }

      if (
        (error.response && error.response.status === 404) ||
        (error.response && error.response.status === 400) ||
        (error.response && error.response.status === 401)
      ) {
        dispatch(logoutAction());
        window.location.href = "/auth";
        return dispatch({
          type: "GETTING_USER_PROFILE",
          errorMessage: "Please login again to continue."
        });
      }

      if (!error.response) {
        return dispatch({
          type: "GETTING_USER_PROFILE",
          errorMessage: "Please check your internet connection and try again."
        });
      }
    });
};

export const getAllRewardsAction = () => async (dispatch) => {
  dispatch({ type: "GETTING_ALL_REWARDS" });
  metaBinManagement
    .get("/reward/read")
    .then((res) => {
      dispatch({ type: "GOT_REWARDS", payload: res.data });
    })
    .catch((error) => {
      if (error.response && error.response.status === 502) {
        return dispatch({
          type: "CANNOT_GET_REWARDS",
          errorMessage:
            "We are having trouble connecting to our servers. Please try again later."
        });
      }

      if (
        (error.response && error.response.status === 404) ||
        (error.response && error.response.status === 400) ||
        (error.response && error.response.status === 401)
      ) {
        dispatch(logoutAction());
        window.location.href = "/auth";
        return dispatch({
          type: "CANNOT_GET_REWARDS",
          errorMessage: "Please login again to continue."
        });
      }

      if (!error.response) {
        return dispatch({
          type: "CANNOT_GET_REWARDS",
          errorMessage: "Please check your internet connection and try again."
        });
      }
    });
};

export const getNotificationAction = () => async (dispatch) => {
  dispatch({ type: "GETTING_NOTIFICATION" });
  metaBinManagement
    .get("/profile/read/notifications")
    .then((res) => {
      dispatch({ type: "GOT_NOTIFICATION", payload: res.data });
    })
    .catch((error) => {
      if (
        (error.response && error.response.status === 404) ||
        (error.response && error.response.status === 400) ||
        (error.response && error.response.status === 401)
      ) {
        dispatch(logoutAction());
        window.location.href = "/auth";
        return dispatch({
          type: "CANNOT_GET_NOTIFICATIONS",
          errorMessage: "Please login again to continue."
        });
      }

      if (!error.response) {
        return dispatch({
          type: "CANNOT_GET_NOTIFICATIONS",
          errorMessage: "Please check your internet connection and try again."
        });
      }
    });
};

export const getALLBrandsAction = () => async (dispatch) => {
  dispatch({ type: "GETTING_BRANDS" });
  metaBinManagement
    .get("/metabins/read/owners")
    .then((res) => {
      dispatch({ type: "GOT_ALL_BRANDS", payload: res.data });
    })
    .catch((error) => {
      if (error.response && error.response.status === 502) {
        return dispatch({
          type: "CANNOT_GET_BRANDS",
          errorMessage:
            "We are having trouble connecting to our servers. Please try again later."
        });
      }

      if (
        (error.response && error.response.status === 404) ||
        (error.response && error.response.status === 400) ||
        (error.response && error.response.status === 401)
      ) {
        dispatch(logoutAction());
        window.location.href = "/auth";
        return dispatch({
          type: "CANNOT_GET_BRANDS",
          errorMessage: "Please login again to continue."
        });
      }

      if (!error.response) {
        return dispatch({
          type: "CANNOT_GET_BRANDS",
          errorMessage: "Please check your internet connection and try again."
        });
      }
    });
};

export const rateExperience = (data, setOpenModal) => async (dispatch) => {
  dispatch(showLoading());
  metaBinManagement
    .post("/rating/create", data)
    .then((res) => {
      dispatch(getSession(res.data.sessionId));
      setOpenModal(false);
    })
    .catch((error) => {
      dispatch(hideLoading());
      toast.error(error.response ? error.response?.data : error.message);

      // if (error.response && error.response.status === 502) {
      //   return dispatch({
      //     type: "CANNOT_GET_BRANDS",
      //     errorMessage:
      //       "We are having trouble connecting to our servers. Please try again later."
      //   });
      // }

      // if (
      //   (error.response && error.response.status === 404) ||
      //   (error.response && error.response.status === 400) ||
      //   (error.response && error.response.status === 401)
      // ) {
      //   dispatch(logoutAction());
      //   window.location.href = "/auth";
      //   return dispatch({
      //     type: "CANNOT_GET_BRANDS",
      //     errorMessage: "Please login again to continue."
      //   });
      // }

      // if (!error.response) {
      //   return dispatch({
      //     type: "CANNOT_GET_BRANDS",
      //     errorMessage: "Please check your internet connection and try again."
      //   });
      // }
    });
};

export const spin2WinGameAction = () => async (dispatch) => {
  dispatch({ type: "ROTATION_NOW" });
  metaBinManagement
    .put("/reward/update/spin-wheel")
    .then((res) => {
      dispatch(getUserProfileAction());
      dispatch({ type: "ROTATION_DONE", payload: res.data });
    })
    .catch((error) => {
      if (error.response && error.response.status === 502) {
        return dispatch({
          type: "CANNOT_RATATION",
          errorMessage:
            "We are having trouble connecting to our servers. Please try again later."
        });
      }

      if (
        (error.response && error.response.status === 404) ||
        (error.response && error.response.status === 400) ||
        (error.response && error.response.status === 401)
      ) {
        dispatch(logoutAction());
        window.location.href = "/auth";
        return dispatch({
          type: "CANNOT_RATATION",
          errorMessage: "Please login again to continue."
        });
      }

      if (!error.response) {
        return dispatch({
          type: "CANNOT_RATATION",
          errorMessage: "Please check your internet connection and try again."
        });
      }
    });
};

export const sendUPINumber = (UPINumber, navigate) => async (dispatch) => {
  dispatch({ type: "SENDING_UPI_NUMBER" });
  metaBinManagement
    .put("/profile/update", {
      UPI_Id: UPINumber
    })
    .then((res) => {
      dispatch({ type: "UPI_NUMBER_SENT" });
      navigate("/game-gongratulations");
    })
    .catch((error) => {
      if (error.response && error.response.status === 502) {
        return dispatch({
          type: "CANNOT_SEND_UPI_NUMBER",
          errorMessage:
            "We are having trouble connecting to our servers. Please try again later."
        });
      }

      if (
        (error.response && error.response.status === 404) ||
        (error.response && error.response.status === 400) ||
        (error.response && error.response.status === 401)
      ) {
        dispatch(logoutAction());
        window.location.href = "/auth";
        return dispatch({
          type: "CANNOT_SEND_UPI_NUMBER",
          errorMessage: "Please login again to continue."
        });
      }

      if (!error.response) {
        return dispatch({
          type: "CANNOT_SEND_UPI_NUMBER",
          errorMessage: "Please check your internet connection and try again."
        });
      }
      dispatch({ type: "CANNOT_SEND_UPI_NUMBER" });
      // toast.error(error.response ? error.response?.data : error.message);
    });
};

export const getSingleSessionAction = (sessionID) => async (dispatch) => {
  dispatch({ type: "GETTING_SINGLE_SESSION" });
  metaBinManagement
    .get(`/session/read/single/${sessionID}`)
    .then((res) => {
      dispatch({ type: "GOT_SESSION_HISTORY", payload: res.data });
    })
    .catch((error) => {
      if (error.response && error.response.status === 502) {
        return dispatch({
          type: "CANNOT_GET_SINGLE_SESSION",
          errorMessage:
            "We are having trouble connecting to our servers. Please try again later."
        });
      }

      if (
        (error.response && error.response.status === 404) ||
        (error.response && error.response.status === 400) ||
        (error.response && error.response.status === 401)
      ) {
        dispatch(logoutAction());
        window.location.href = "/auth";
        return dispatch({
          type: "CANNOT_GET_SINGLE_SESSION",
          errorMessage: "Please login again to continue."
        });
      }

      if (!error.response) {
        return dispatch({
          type: "CANNOT_GET_SINGLE_SESSION",
          errorMessage: "Please check your internet connection and try again."
        });
      }
      dispatch({ type: "CANNOT_GET_SINGLE_SESSION" });
    });
};

export const getAllTransactionsAction = () => async (dispatch) => {
  dispatch({ type: "GETTING_TRANSACTIONS" });
  metaBinManagement
    .get("/transactions/read/my-winnings")
    .then((res) => {
      dispatch({ type: "GOT_TRANSACTIONS", payload: res.data });
    })
    .catch((error) => {
      if (error.response && error.response.status === 502) {
        return dispatch({
          type: "CANNOT_GET_TRANSACTIONS",
          errorMessage:
            "We are having trouble connecting to our servers. Please try again later."
        });
      }

      if (
        (error.response && error.response.status === 404) ||
        (error.response && error.response.status === 400) ||
        (error.response && error.response.status === 401)
      ) {
        dispatch(logoutAction());
        window.location.href = "/auth";
        return dispatch({
          type: "CANNOT_GET_TRANSACTIONS",
          errorMessage: "Please login again to continue."
        });
      }

      if (!error.response) {
        return dispatch({
          type: "CANNOT_GET_TRANSACTIONS",
          errorMessage: "Please check your internet connection and try again."
        });
      }
    });
};

export const addBarcodeAction = (data) => async (dispatch) => {
  (await metaBinManagement.post("/barcodes/create", data))
    .then((res) => {
      toast.success("barcode has been added");
    })
    .catch((err) => {
      console.log(err);
      toast.success("err");
    });
};
