import axios from "axios";
import {
  authenticationService,
  metabin_management,
  planetWarriorService,
} from "./config";

function getAuthURL() {
  return `${authenticationService.baseURL}`;
}

function getAuth() {
  let Auth = axios.create(authenticationService);
  const token = localStorage.getItem("CCXT");
  if (token) Auth.defaults.headers.common["X-Authentication-Token"] = token;
  return Auth;
}

function getMetabinManagementURL() {
  return `${metabin_management.baseURL}`;
}

function getMetaBinManagement() {
  let Auth = axios.create(metabin_management);
  const metaBinToken = localStorage.getItem("METABIN_CCXT");

  if (metaBinToken)
    Auth.defaults.headers.common["X-Authentication-Token"] = metaBinToken;
  return Auth;
}

// planet warrior server
function getPlanetWarriorURL() {
  return `${planetWarriorService.baseURL}`;
}

function getPlanetWarrior() {
  let Auth = axios.create(planetWarriorService);
  const token = localStorage.getItem("METABIN_CCXT");

  if (token) Auth.defaults.headers.common["X-Authentication-Token"] = token;
  return Auth;
}

export const Auth = getAuth();
export const AuthURL = getAuthURL();
export const metaBinManagement = getMetaBinManagement();
export const metaBinManagementURL = getMetabinManagementURL();
export const metaBin = getPlanetWarrior();
export const metaBinURL = getPlanetWarriorURL();
