import React from 'react'
import {GoogleApiWrapper} from 'google-maps-react';
 

function SearchBin() {
  return (
    <div>SearchBin</div>
  )
}

export default SearchBin