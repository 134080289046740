import React, { useState } from "react";
import HeaderBack from "../components/HeaderBack";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Preloader } from "react-materialize";
import { sendUPINumber } from "../redux/action/metaBinAction";
import Celebration from "../components/Celebration";
import "animate.css";
import { getUserProfileAction } from "../redux/action/metaBinAction";
import { useEffect } from "react";

function EnterUPIDetails() {
  const { state } = useLocation();

  const { userProfile } = useSelector((state) => state.userProfileStore);

  const navigate = useNavigate();

  const [UPIID, setUPIID] = useState("");
  const { primary } = useSelector((state) => state.theme);
  const { loading } = useSelector((state) => state.sendUPINumber);

  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const handleSubmit = () => {
    if (!UPIID) {
      setError("Please Enter UPI Number.");
    } else {
      dispatch(sendUPINumber(UPIID, navigate));
    }
  };

  useEffect(() => {
    setUPIID(userProfile.UPI_Id);
  }, [dispatch, userProfile.UPI_Id]);

  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);

  return (
    <div>
      <HeaderBack title="Spin & Win" />
      <div className="flex column align-center p-1">
        <b
          className="normal-size mt-2   "
          style={{ textAlign: "center", color: primary }}
        >
          {state?.res?.message}
        </b>

        <p style={{ textAlign: "center" }}>{state?.res?.widthrawalMessage}</p>
        <Celebration />
        <img
          src="../images/won-cup.png"
          style={{ width: "200px", height: "220px" }}
          className="animate__animated animate__bounce"
        />

        <div className="full-width mt-2 ">
          <p>Enter your UPI ID </p>
          <div className="input-field">
            <input
              className="browser-default "
              placeholder="Enter your UPI ID"
              type={"text"}
              value={UPIID}
              onChange={(e) => {
                setUPIID(e.target.value);
                setError("");
              }}
            />
          </div>
          {error && <p className="red-text small-text ">{error}</p>}

          <button
            className="btn-submit"
            type="submit"
            style={{ backgroundColor: primary }}
            onClick={() => handleSubmit()}
          >
            {!loading ? (
              "NEXT"
            ) : (
              <Preloader active color="#fff" flashing={false} size="small" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default EnterUPIDetails;
