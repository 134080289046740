import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllRewardsAction } from "../redux/action/metaBinAction";
import { Preloader } from "react-materialize";
import { useNavigate } from "react-router-dom";
import CardLoading from "./CardLoading";
import { motion } from "framer-motion";
import { MOTION_VARIANTS } from "../helper/constants";
import ErrorHandler from "./ErrorHandler";

function RewardsList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { rewards, loading, error } = useSelector(
    (state) => state.rewardsStore
  );

  useEffect(() => {
    dispatch(getAllRewardsAction());
  }, [dispatch]);

  if (error) {
    <ErrorHandler error={error} refresh={getAllRewardsAction()} />;
  }
  return (
    <motion.div
      custom={{ direction: "forward" }}
      initial="initial"
      animate="in"
      exit="out"
      variants={MOTION_VARIANTS}
      className="white p-1 rewardsList "
    >
      <img src="../images/cercCoins.png" style={{ width: "100%" }} />

      <div className="mt-1 ">
        <b className="normal-size  ">Rewards Available</b>
        {loading && <CardLoading />}
        {rewards.length === 0 && loading == false && (
          <div className="flex column align-center mt-4 ">
            <span
              className="material-symbols-outlined grey-text "
              style={{ fontSize: "10rem" }}
            >
              list_alt
            </span>
            <p className="normal-size grey-text ">No rewards found.</p>
          </div>
        )}
        <div className="flex   flex-wrap ">
          {rewards.map((item, index) => {
            return (
              <div
                key={index}
                className="single-reward "
                style={{ width: "50%", padding: "5px" }}
                onClick={() =>
                  navigate(`/singleRewardPage/${item._id}`, {
                    state: { item }
                  })
                }
              >
                <img src="../images/3.png" style={{ width: "100%" }} />
                <span className="flex align-center gap-1  justify-center  yellow  ">
                  <img src="../images/coin.png" style={{ width: "30px" }} />
                  <b>{item.pointsRequired} CERC coins</b>
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </motion.div>
  );
}

export default RewardsList;
