import React , {useEffect} from "react";
import { useLottie } from "lottie-react";
import celebration from "../lotti/celebration.json";
import { Howl, Howler } from "howler";

function Celebration() {
  const options = {
    animationData: celebration,
    loop: false,
  };
  const { View } = useLottie(options);

  function playSound() {
    const audio = new Howl({
      src: ["/effects/notification_sound.mp3"],
    });
  
    audio.play();
  }

  useEffect(()=>{
    playSound()

  },[])
  return <div className="celebration-view">{View}
 
  </div>;
}

export default Celebration;
