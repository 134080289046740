import React, { useState } from "react";
import Frame from "../layout/Frame";
import { QrReader } from "react-qr-reader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function ScanScreen() {
  const { primary } = useSelector((state) => state.theme);

  const state = {
    delay: 100,
    result: "No result"
  };

  const previewStyle = {
    height: 330,
    width: "300px",
    borderRadius: "20px",
    position: "absolute",
    objectFit: "cover",
    borderRadius: "10px"
  };

  return (
    <Frame>
      <div className="page-bg" style={{ backgroundColor: primary }}>
        {/* <Header backgroundColor={"#555"} primaryColor={"#fff"} /> */}
        <div className="scan-QR-code flex column align-center ">
          <div className="flex justify-center pt-2   ">
            <p className="white-color"> Please Scan The QR Code On The Bin.</p>
          </div>

          <div
            style={{ position: "relative", width: "90vw" }}
            className="flex column justify-center align-center QR-code-bg "
          >
            <QrReader
              onResult={(result, error) => {
                if (result) {
                  window.location.href = result;
                }

                if (!!error) {
                  console.info(error);
                }
              }}
              constraints={{
                facingMode: { exact: "environment" }
              }}
              delay={state.delay}
              style={previewStyle}
              videoStyle={{
                width: "300px",
                height: "310px",
                objectFit: "cover",
                borderRadius: "10px"
              }}
              containerStyle={{
                width: "300px",
                height: "310px",
                objectFit: "cover",
                borderRadius: "10px"
              }}
              className="qr-code-scanner"
            />

            <span className="qr-code-scanner-line"></span>
          </div>

          <div className="flex justify-center">
            <p className="white-color mt-1"> Scanning...</p>
          </div>
        </div>
      </div>
    </Frame>
  );
}

export default ScanScreen;

// <QrReader
//   delay={state.delay}
//   style={previewStyle}
//   facingMode="rear"
//   constraints={{
//     facingMode: { exact: "environment" }
//   }}
//   onError={(e) => console.log("error")}
//   onScan={handleScan}
// />
