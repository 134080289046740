import React from "react";
import UserLoading from "../UserLoading";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

function AccountHeader() {
  const navigate = useNavigate();
  const { userLocation, loading } = useSelector((state) => state.userLocation);

  const { primary } = useSelector((state) => state.theme);
  const { userProfile } = useSelector((state) => state.userProfileStore);
  console.log(userProfile)
  return (
    <div
      className="flex space-between p-1"
      style={{ backgroundColor: primary }}
    >
      <div className="flex align-center ">
        <span className="material-symbols-outlined white-text " role="button">
          location_on
        </span>
        &nbsp;
        {!loading ? (
          <p className="white-text">
            {userLocation.plus_code?.compound_code
              ?.split(" ")
              .slice(1, 3)
              .join(" ")}
          </p>
        ) : (
          <UserLoading />
        )}
      </div>
      <div
        className="    flex justify-end  "
        onClick={() => navigate("/history")}
      >
        <span
          className="flex align-center white justify-center "
          style={{ borderRadius: "3px", padding: "2px 10px" }}
        >
          <img
            src="../images/coin.png"
            style={{ width: "28px", height: "28px" }}
            alt="coin"
          />
          &nbsp;
          <b> {userProfile.cerc_coins ? userProfile.cerc_coins : 0}</b>
        </span>
      </div>
    </div>
  );
}

export default AccountHeader;
