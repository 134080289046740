export const authenticationService = {
  baseURL: 'https://api.authentication.thinktrash.co'
};

export const planetWarriorService = {
  baseURL: 'https://server1.metabins.cerclex.com/',
};

export const metabin_management = {
  baseURL: 'https://management.metabins.co/',
};

