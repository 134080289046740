import React, { useEffect } from "react";
import Frame from "../layout/Frame";
import HeaderBack from "../components/HeaderBack";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationAction } from "../redux/action/metaBinAction";
import { Preloader } from "react-materialize";
import { formatDate } from "../helper/helper";
import BarLoading from "../components/BarLoading";
import ErrorHandler from "../components/ErrorHandler";

function Notifications() {
  const dispatch = useDispatch();
  const { primary } = useSelector((state) => state.theme);
  const { loading, notifications, error } = useSelector(
    (state) => state.notificationsList
  );

  useEffect(() => {
    dispatch(getNotificationAction());
  }, [dispatch]);

  if (error) {
    return (
      <div>
        <HeaderBack title="Notifications" />
        <ErrorHandler error={error} refresh={getNotificationAction()} />
      </div>
    );
  }
  return (
    <div className="page-bg">
    <HeaderBack title="Notifications" />
      {loading && <BarLoading />}

      {notifications.length === 0 && loading === false && (
        <div
          className="flex column align-center justify-center "
          style={{ height: "90vh " }}
        >
          <img src="../images/notification.png" style={{ width: "120px" }} />
          <p className="grey-text mt-1">You Don't Have Any Notification.</p>
        </div>
      )}

      {notifications.map((item, index) => {
        return (
          <div className="pt-1" key={index} >
            <div
              className="flex border-bottom "
              style={{ padding: "12px 12px" }}
            >
              <img
                src="../images/notification.jpg"
                style={{ width: "3rem", height: "3rem" }}
                className="circle"
              />
              &nbsp;
              <div>
                <b>MetaBin App</b>
                <p className="small-text ">{item.message}</p>
              </div>
              <p className="small-text">{formatDate(item.createdAt)}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Notifications;
