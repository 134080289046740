import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLottie } from "lottie-react";
import congratulation from "../lotti/congratulation.json";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "react-materialize";
import { getSession, rateExperience } from "../redux/action/metaBinAction";
import { isEmpty } from "lodash";
import { Preloader } from "react-materialize";

function Congratulations() {
  const { primary } = useSelector((state) => state.theme);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState({ stars: 0, standard: "" });
  const { id: sessionID } = useParams();

  const { session, loading } = useSelector((state) => state.session);
  const review = [
    { stars: 1, standard: "Poor" },
    { stars: 2, standard: "Fair" },
    { stars: 3, standard: "Good" },
    { stars: 4, standard: "Very Good" },
    { stars: 5, standard: "Exellent" }
  ];

  const options = {
    animationData: congratulation,
    loop: true
  };
  const { View } = useLottie(options);

  function handleRating(stars, standard) {
    setRating({ ...rating, stars: stars, standard: standard });
  }

  useEffect(() => {
    if (isEmpty(session)) dispatch(getSession(sessionID));
  }, [dispatch, session, sessionID]);

  useEffect(() => {
    if (isEmpty(session)) return;
    if (session.rating) setOpenModal(false);

    setTimeout(() => {
      if (!session.rating) setOpenModal(true);
    }, 3000);
  }, [session]);

  function rate(e) {
    e.preventDefault();
    if (rating.stars === 0) return;
    if (!session) return;
    const data = {
      rating: rating.stars,
      comment,
      metabin: session.metabin._id,
      session: session._id
    };
    dispatch(rateExperience(data, setOpenModal));
  }

  return (
    <div
      className="page-bg flex column center pt-3 align-center "
      style={{ backgroundColor: primary, minHeight: "100vh" }}
    >
      <p className="white-text big-text ">Congrats!</p>
      <div style={{ width: "70%" }}>{View}</div>

      {loading ? (
        <div className="flex justify-center">
          <Preloader active color={primary} flashing />
        </div>
      ) : (
        <>
          <div className="white-color ml-3 mr-3">
            <p>
              Thank you Planet Warrior for your invaluable contribution towards
              circularity! Mother Earth is grateful for your efforts.
            </p>
            <h4>You've earned {session.productCount * 3} CERC coins</h4>
          </div>
          <div className="flex full-width center mt-3 space-between gap-2 pl-2 pr-2 ">
            <button
              className="btn-submit yellow-text "
              onClick={() => navigate("/home")}
              style={{ backgroundColor: primary, borderColor: "yellow" }}
            >
              Use Later
            </button>
            <button
              className="btn-submit"
              style={{ backgroundColor: "yellow", color: primary }}
              onClick={() => navigate("/rewards")}
            >
              Redeem Now
            </button>
          </div>
        </>
      )}

      <Modal
        actions={[]}
        bottomSheet={false}
        fixedFooter={false}
        id="Modal-1"
        open={openModal}
        options={{}}
      >
        <div
          className="flex justify-center column align-center"
          style={{ textAlign: "center" }}
        >
          <b className="normal-size">Give feedback</b>
          <p className="grey-text small-text">
            How did you find this experience?
          </p>
          {
            <b style={{ color: primary }} className="mv-1">
              {rating.standard}
            </b>
          }
          <div className="flex gap-1">
            {review.map((i, x) => {
              return rating.stars >= i.stars ? (
                <span
                  class="material-symbols-outlined"
                  onClick={() => handleRating(i.stars, i.standard)}
                  style={{ color: primary }}
                  key={x}
                >
                  star
                </span>
              ) : (
                <span
                  class="material-symbols-outlined"
                  onClick={() => handleRating(i.stars, i.standard)}
                >
                  star
                </span>
              );
            })}
          </div>

          <textarea
            placeholder="Your Message..."
            className="mt-1 input-field "
            onChange={(e) => setComment(e.target.value)}
            value={comment}
          />

          <div className="flex gap-1 mt-2 ">
            <Button
              className="small-btn-submit"
              flat
              modal="close"
              node="button"
              style={{ color: primary, border: `1px solid ${primary}` }}
            >
              Cancel
            </Button>
            <Button
              className="small-btn-submit"
              style={{ backgroundColor: primary, color: "#fff" }}
              onClick={rate}
              disabled={rating.stars === 0}
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Congratulations;
